import Table from "@src/components/DataTable/Table";
import { Card, CardBody } from "reactstrap";
import {
  useGetItemsQuery,
  useExportItemsMutation,
} from "@src/redux/stock/item";
import ItemColumns from "./columns";
import ItemModal from "./itemModal";
import UpdateQuantityModal from "./UpdateQuantityModal";
import { useState, useEffect } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import Loader from "@src/components/shared/Loader";
import Export from "../../common/Export";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";

const Items = () => {
  const [visible, setVisible] = useState(false);
  const [updateModelVisibility, setUpdateModelVisibility] = useState(false);
  const [itemData, setItemData] = useState(null);
  const [quantitySettlementMode, setQuantitySettlementMode] = useState(false);

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: null,
    keyword: "",
  });

  const initialDtFilters = {
    name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    availablePieces: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    availableInBoxes: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    totalWeight: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    minimumBoxLimit: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    piecesPerBox: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  const [dtFilters, setDtFilters] = useState(initialDtFilters);
  const { data, isLoading } = useGetItemsQuery(filters);
  const [exportItems, { isLoading: isLoadingExportedItems }] =
    useExportItemsMutation();
  const [isExportDisabled, setIsExportDisabled] = useState(false);
  useEffect(() => {
    setIsExportDisabled(!data?.items || data.items.length === 0);
  }, [data?.items]);

  const clearFilters = () => {
    setDtFilters(initialDtFilters);
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: null,
      keyword: "",
    });
  };
  const handleUpdateQuantityClick = (item) => {
    setItemData(item);
    setUpdateModelVisibility(true);
    setQuantitySettlementMode(false);
  };
  const handleQuantitySettlementClick = (item) => {
    setItemData(item);
    setUpdateModelVisibility(true);
    setQuantitySettlementMode(true);
  };
  const actionTemplate = () => (
    <Export
      filters={filters}
      exportFunction={exportItems}
      exportedEntity={"items"}
      isLoading={isLoadingExportedItems}
      disabled={isExportDisabled}
    />
  );

  return (
    <>
      {isLoading && <Loader />}
      <BreadCrumbs pageName="itemList" pageTitle="Item List" />
      <Card>
        <CardBody>
          <Table
            metaData={{
              ...data?.metadata,
              loading: isLoading,
            }}
            columns={ItemColumns(
              handleUpdateQuantityClick,
              handleQuantitySettlementClick
            )}
            values={data?.items || []}
            dataKey="id"
            emptyMessage="No Stock Items Found..."
            filters={dtFilters}
            setTableState={setFilters}
            clearFilter={clearFilters}
            actionTemplate={actionTemplate}
            displayAdd={true}
            handleAdd={() => setVisible(true)}
            tableState={filters}
          />
        </CardBody>
      </Card>
      <ItemModal visible={visible} toggle={() => setVisible(false)} />
      <UpdateQuantityModal
        visible={updateModelVisibility}
        toggle={() => setUpdateModelVisibility(false)}
        itemData={itemData}
        quantitySettlement={quantitySettlementMode}
      />
    </>
  );
};

export default Items;
