import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Table,
} from "reactstrap";
import { formatDateTime } from "@src/utility/Utils";

const OwnerDetailsModal = ({ isOpen, toggle, owner }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        Details of {owner?.passengerName}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="12">
            <strong>Seat Number:</strong> {owner?.seatNumber}
          </Col>
          <Col sm="12">
            <strong>Booking Reference:</strong> {owner?.bookingReference}
          </Col>
          <Col sm="12">
            <strong>Email:</strong> {owner?.email}
          </Col>
          <Col sm="12">
            <strong>Phone:</strong> {owner?.homePhone || owner?.workPhone}
          </Col>
        </Row>
        <Table responsive bordered className="mt-2">
          <thead>
            <tr>
              <th className="text-capitalize">#</th>
              <th className="text-capitalize">Action Time</th>
              <th className="text-capitalize">Comments</th>
            </tr>
          </thead>
          <tbody>
            {owner?.actions?.length > 0 ? (
              owner.actions.map((action, actionIndex) => (
                <tr key={action.id}>
                  <td>{actionIndex + 1}</td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {" "}
                    {action.createdOnUtc &&
                      formatDateTime(new Date(action.createdOnUtc))}
                  </td>
                  <td>{action.comments}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3">No actions available.</td>
              </tr>
            )}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="warning" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default OwnerDetailsModal;
