import { mainApi } from "../mainApi";

export const itemTypeApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getItemTypes: builder.query({
      query: (filters) => ({
        url: `/founditems/itemType/search`,
        method: "POST",
        body: filters,
      }),
      transformResponse: (response, meta, arg) => {
        const { data, ...metadata } = response;
        return { items: data, metadata };
      },
      providesTags: (result) => [{ type: "FoundItemTypes", id: "Search" }],
      keepUnusedDataFor: 300,
    }),
    addItemType: builder.mutation({
      query: (itemTypeData) => ({
        url: `/founditems/itemType`,
        method: "POST",
        body: itemTypeData,
      }),
      invalidatesTags: [
        { type: "FoundItemTypes", id: "List" },
        { type: "FoundItemTypes", id: "Search" },
      ],
    }),
    getItemTypeList: builder.query({
      query: () => ({
        url: `/founditems/itemType/list`,
        method: "GET",
      }),
      providesTags: (result) => [{ type: "FoundItemTypes", id: "List" }],
      keepUnusedDataFor: 300,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetItemTypesQuery,
  useAddItemTypeMutation,
  useGetItemTypeListQuery,
} = itemTypeApi;
