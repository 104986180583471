import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const breadcrumbMap = {
  reasonList: [{ label: "Reasons", active: true }],
  leftBehindList: [{ label: "Left-Behind-Items", active: true }],
  addLeftBehind: [
    {
      label: "Left-Behind-Items",
      path: "/baggage-management/left-behind/list",
    },
    { label: "Add", active: true },
  ],
  updateLeftBehind: [
    {
      label: "Left-Behind-Items",
      path: "/baggage-management/left-behind/list",
    },
    { label: "Update", active: true },
  ],
  forwardLeftBehind: [
    {
      label: "Left-Behind-Items",
      path: "/baggage-management/left-behind/list",
    },
    { label: "Forward", active: true },
  ],
  leftBehindDetails: [
    {
      label: "Left-Behind-Items",
      path: "/baggage-management/left-behind/list",
    },
    { label: "Details", active: true },
  ],
  itemList: [{ label: "Items", active: true }],
  quantityTypeList: [{ label: "Quantity-Types", active: true }],
  orderList: [{ label: "Orders", active: true }],
  orderDetails: [
    { label: "Orders", path: "/baggage-management/stock/orders" },
    { label: "Order Details", active: true },
  ],
  addOrder: [
    { label: "Orders", path: "/baggage-management/stock/orders" },
    { label: "Add", active: true },
  ],
  updateOrder: [
    { label: "Orders", path: "/baggage-management/stock/orders" },
    { label: "Update", active: true },
  ],
  approveOrder: [
    { label: "Orders", path: "/baggage-management/stock/orders" },
    { label: "Approve", active: true },
  ],
  historyList: [{ label: "History", active: true }],
  orderTagList: [{ label: "Order-Tags", active: true }],
  foundItemTypes: [{ label: "Found-Item Types", active: true }],
  foundItemList: [{ label: "Found-Items", active: true }],
  addFoundItem: [
    { label: "Found-Items", path: "/baggage-management/found-items/list" },
    { label: "Add", active: true },
  ],
  updateFoundItem: [
    { label: "Found-Items", path: "/baggage-management/found-items/list" },
    { label: "Update", active: true },
  ],
  foundItemDetails: [
    { label: "Found-Items", path: "/baggage-management/found-items/list" },
    { label: "Details", active: true },
  ],
  settingList: [{ label: "Settings", active: true }],
};

const BreadCrumbs = ({ pageName, pageTitle }) => {
  const items = breadcrumbMap[pageName] || [];

  const breadcrumbItems = [
    { label: "Home", path: "/baggage-management/home" },
    ...items,
  ];

  return (
    <div className="content-header row">
      <div className="content-header-left col-md-9 col-12 mb-2">
        <div className="row breadcrumbs-top">
          <div className="col-12">
            {pageTitle && (
              <h3 className="content-header-title float-start mb-0 ms-1">
                {pageTitle}
              </h3>
            )}
            <div className="breadcrumb-wrapper vs-breadcrumbs d-sm-block d-none col-12">
              <Breadcrumb>
                {breadcrumbItems.map((item, index) => (
                  <BreadcrumbItem
                    key={index}
                    active={item.active}
                    className={item.active ? "text-muted" : ""}
                  >
                    {item.path ? (
                      <Link to={item.path}>{item.label}</Link>
                    ) : (
                      item.label
                    )}
                  </BreadcrumbItem>
                ))}
              </Breadcrumb>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BreadCrumbs.propTypes = {
  pageName: PropTypes.string.isRequired,
  pageTitle: PropTypes.string,
};

export default BreadCrumbs;
