import { mainApi } from "../mainApi";

export const quantityTypeApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getQuantityTypeList: builder.query({
      query: () => ({
        url: `/stock/quantityType/list`,
        method: "GET",
      }),
      providesTags: (result) => [{ type: "QuantityTypes", id: "List" }],
      keepUnusedDataFor: 300,
    }),
    getQuantityTypes: builder.query({
      query: (filters) => ({
        url: `/stock/quantityType/search`,
        method: "POST",
        body: filters,
      }),
      transformResponse: (response, meta, arg) => {
        const { data, ...metadata } = response;
        return { items: data, metadata };
      },
      providesTags: (result) => [{ type: "QuantityTypes", id: "Search" }],
      keepUnusedDataFor: 300,
    }),
    addQuantityType: builder.mutation({
      query: (data) => ({
        url: `/stock/quantityType/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        { type: "QuantityTypes", id: "List" },
        { type: "QuantityTypes", id: "Search" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetQuantityTypeListQuery,
  useGetQuantityTypesQuery,
  useAddQuantityTypeMutation,
} = quantityTypeApi;
