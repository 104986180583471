import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

async function OpenSweetAlert({
  title,
  html,
  icon = "success",
  confirmButtonClasses = "btn btn-success",
  animation = "fadeInDown",
  confirmButtonText = "Ok",
  text,
}) {
  await MySwal.fire({
    icon: icon,
    title: title,
    html: html,
    confirmButtonText: confirmButtonText,
    showClass: {
      popup: `animate__animated animate__${animation}`,
      text: text,
    },
    customClass: {
      confirmButton: confirmButtonClasses,
    },
  });
}

export default OpenSweetAlert;
