import Table from "@src/components/DataTable/Table";
import { Card, CardBody } from "reactstrap";
import { useGetItemTypesQuery } from "@src/redux/found-item/item-type";
import itemTypeColumns from "./columns";
import { useState } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import ItemTypeModal from "./itemTypeModal";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";

const ItemTypes = () => {
  const [visible, setVisible] = useState(false);
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: null,
    keyword: "",
  });

  const initialDtFilters = {
    name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    description: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    isValuable: { value: null, matchMode: FilterMatchMode.EQUALS },
  };

  const [dtFilters, setDtFilters] = useState(initialDtFilters);
  const { data, isLoading } = useGetItemTypesQuery(filters);

  const clearFilters = () => {
    setDtFilters(initialDtFilters);
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: null,
      keyword: "",
    });
  };

  return (
    <>
      <BreadCrumbs pageName="foundItemTypes" pageTitle="Found-Item Types" />
      <Card>
        <CardBody>
          <Table
            metaData={{
              ...data?.metadata,
              loading: isLoading,
            }}
            columns={itemTypeColumns}
            values={data?.items || []}
            dataKey="id"
            emptyMessage="No Found Item Types Found..."
            filters={dtFilters}
            setTableState={setFilters}
            clearFilter={clearFilters}
            displayAdd={true}
            handleAdd={() => setVisible(true)}
            tableState={filters}
          />
        </CardBody>
      </Card>
      <ItemTypeModal visible={visible} toggle={() => setVisible(false)} />
    </>
  );
};

export default ItemTypes;
