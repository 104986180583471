import React, { useState } from "react";
import { Card, CardHeader, Button, ButtonGroup } from "reactstrap";
import {
  faCheck,
  faXmark,
  faClockRotateLeft,
  faEdit,
  faCancel,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RejectModal from "./RejectModal";
import ReverseModal from "./ReverseModal";
import CancelModal from "./CancelModal";

const ActionToolbar = ({ order }) => {
  const [rejectModelVisibility, setRejectModelVisibility] = useState(false);
  const [reverseModelVisibility, setReverseModelVisibility] = useState(false);
  const [cancelModelVisibility, setCancelModelVisibility] = useState(false);

  if (order.status != "pending" && order.status != "approved") return <></>;

  return (
    <>
      <Card>
        <CardHeader
          className="border-bottom p-1 flex flex-wrap gap-2"
          style={{ justifyContent: "right" }}
        >
          <ButtonGroup className="mb-1">
            {order.status == "pending" ? (
              <>
                <Button
                  outline
                  type="button"
                  color="secondary"
                  href={`/baggage-management/stock/orders/${order.id}/update`}
                  tag="a"
                >
                  <FontAwesomeIcon icon={faEdit} className="font-medium-3" />{" "}
                  &nbsp; Update
                </Button>
                <Button
                  outline
                  type="button"
                  color="secondary"
                  onClick={() => setRejectModelVisibility(true)}
                >
                  <FontAwesomeIcon icon={faXmark} className="font-medium-3" />{" "}
                  &nbsp; Reject
                </Button>
                <Button
                  outline
                  type="button"
                  color="secondary"
                  href={`/baggage-management/stock/orders/${order.id}/approve`}
                >
                  <FontAwesomeIcon icon={faCheck} className="font-medium-3" />{" "}
                  &nbsp; Approve
                </Button>
                <Button
                  outline
                  type="button"
                  color="secondary"
                  onClick={() => setCancelModelVisibility(true)}
                >
                  <FontAwesomeIcon icon={faCancel} className="font-medium-3" />{" "}
                  &nbsp; Cancel
                </Button>
              </>
            ) : (
              ""
            )}
            {order.status == "approved" ? (
              <>
                <Button
                  outline
                  type="button"
                  color="secondary"
                  onClick={() => setReverseModelVisibility(true)}
                >
                  <FontAwesomeIcon
                    icon={faClockRotateLeft}
                    className="font-medium-3"
                  />{" "}
                  &nbsp; Reverse
                </Button>
              </>
            ) : (
              ""
            )}
          </ButtonGroup>
        </CardHeader>
      </Card>
      <RejectModal
        visible={rejectModelVisibility}
        toggle={() => setRejectModelVisibility(false)}
        orderData={order}
      />
      <ReverseModal
        visible={reverseModelVisibility}
        toggle={() => setReverseModelVisibility(false)}
        orderData={order}
      />
      <CancelModal
        visible={cancelModelVisibility}
        toggle={() => setCancelModelVisibility(false)}
        orderData={order}
      />
    </>
  );
};

export default ActionToolbar;
