import React, { useState } from "react";
import { Card, CardHeader, Button } from "reactstrap";
import {
  faCheck,
  faXmark,
  faClockRotateLeft,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RejectModal from "./RejectModal";
import ReverseModal from "./ReverseModal";

const ActionToolbar = ({ order }) => {
  const [rejectModelVisibility, setRejectModelVisibility] = useState(false);
  const [reverseModelVisibility, setReverseModelVisibility] = useState(false);

  if (order.status != "pending" && order.status != "approved") return <></>;

  return (
    <>
      <Card>
        <CardHeader
          className="border-bottom p-1 flex flex-wrap gap-2"
          style={{ justifyContent: "right" }}
        >
          {order.status == "pending" ? (
            <>
              <Button.Ripple
                type="button"
                color="dark"
                href={`/baggage-management/stock/orders/${order.id}/update`}
                tag="a"
              >
                <FontAwesomeIcon icon={faEdit} className="font-medium-3" />{" "}
                &nbsp; Update
              </Button.Ripple>
              <Button.Ripple
                type="button"
                color="danger"
                onClick={() => setRejectModelVisibility(true)}
              >
                <FontAwesomeIcon icon={faXmark} className="font-medium-3" />{" "}
                &nbsp; Reject
              </Button.Ripple>
              <Button.Ripple
                type="button"
                href={`/baggage-management/stock/orders/${order.id}/approve`}
                color="primary"
              >
                <FontAwesomeIcon icon={faCheck} className="font-medium-3" />{" "}
                &nbsp; Approve
              </Button.Ripple>
            </>
          ) : (
            ""
          )}
          {order.status == "approved" ? (
            <>
              <Button.Ripple
                type="button"
                color="warning"
                onClick={() => setReverseModelVisibility(true)}
              >
                <FontAwesomeIcon
                  icon={faClockRotateLeft}
                  className="font-medium-3"
                />{" "}
                &nbsp; Reverse
              </Button.Ripple>
            </>
          ) : (
            ""
          )}
        </CardHeader>
      </Card>
      <RejectModal
        visible={rejectModelVisibility}
        toggle={() => setRejectModelVisibility(false)}
        orderData={order}
      />
      <ReverseModal
        visible={reverseModelVisibility}
        toggle={() => setReverseModelVisibility(false)}
        orderData={order}
      />
    </>
  );
};

export default ActionToolbar;
