import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Col,
  Row,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { TextInput, CalendarInput, SelectInput } from "@src/components/inputs";
import { useForwardLzBaggageItemMutation } from "@src/redux/lz-baggage/list";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import LoadingButton from "@src/components/shared/LoadingButton";
import {
  faCalendar,
  faPlane,
  faTag,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { useGetStationListQuery } from "@src/redux/station/station";

const ForwardModal = ({ isVisible, toggle, baggageData }) => {
  const [forwardLzBaggage, { isLoading }] = useForwardLzBaggageItemMutation();
  const { data: stationList } = useGetStationListQuery();

  const forwardSchema = yup.object().shape({
    rushTagNumber: yup.string().required("Rush Tag Number is required"),
    flightNumber: yup.string().required("Flight Number is required"),
    flightDate: yup.date().required("Flight Date is required"),
    destination: yup.string().required("Destination is required"),
  });

  const formMethods = useForm({
    resolver: yupResolver(forwardSchema),
    mode: "onSubmit",
    defaultValues: {
      rushTagNumber: "",
      flightNumber: "",
      flightDate: "",
      destination: "",
    },
  });

  useEffect(() => {
    if (isVisible) {
      formMethods.reset();
    }
  }, [isVisible, formMethods]);

  const handleFormSubmit = async (formData) => {
    try {
      const command = {
        id: baggageData.id,
        RushTagNumber: formData.rushTagNumber,
        FlightNumber: formData.flightNumber,
        FlightDate: format(new Date(formData.flightDate), "yyyy-MM-dd"),
        Destination: formData.destination,
      };

      await forwardLzBaggage(command).unwrap();
      SuccessToast("Luggage successfully forwarded!");
      toggle();
    } catch (error) {
      console.error("Forwarding luggage failed:", error);
    }
  };

  return (
    <Modal isOpen={isVisible} toggle={toggle} size="lg">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>Forward Luggage</ModalHeader>
          <ModalBody>
            <Row>
              <Col md={6}>
                <div className="mb-2">
                  <CalendarInput
                    twoLines
                    label="Flight Date"
                    name="flightDate"
                    icon={faCalendar}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-2">
                  <TextInput
                    twoLines
                    label="Flight Number"
                    name="flightNumber"
                    icon={faPlane}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-2">
                  <SelectInput
                    twoLines
                    icon={faMapMarkerAlt}
                    label="Destination"
                    name="destination"
                    options={
                      stationList?.map((type) => ({
                        value: type.id,
                        label: type.id,
                      })) || []
                    }
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-2">
                  <TextInput
                    twoLines
                    label="Rush Tag Number"
                    name="rushTagNumber"
                    icon={faTag}
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <LoadingButton loading={isLoading} type="submit" color="primary">
              Save
            </LoadingButton>
            <Button type="button" color="warning" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default ForwardModal;
