import { mainApi } from "../mainApi";

export const lzBaggageListApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getLzBaggageList: builder.query({
      query: (filters) => ({
        url: `/LzBaggage/search`,
        method: "POST",
        body: filters,
      }),
      transformResponse: (response, meta, arg) => {
        const { data, ...metadata } = response;
        return { items: data, metadata };
      },
      providesTags: (result) => [{ type: "LzBaggageList", id: "Search" }],
    }),
    exportLzBaggageItems: builder.mutation({
      query: (filters) => ({
        url: `/LzBaggage/export`,
        method: "POST",
        body: filters,
        responseHandler: (response) => response.blob(),
      }),
    }),
    addLzBaggageItem: builder.mutation({
      query: (formData) => {
        return {
          url: `/LzBaggage`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [{ type: "LzBaggageList", id: "Search" }],
    }),
    getLzBaggage: builder.query({
      query: (id) => ({
        url: `/LzBaggage/${id}`,
        method: "GET",
      }),
      providesTags: (result) => [{ type: "LzBaggageList", id: "Details" }],
    }),
    updateLzBaggageItem: builder.mutation({
      query: ({ id, formData }) => {
        return {
          url: `/LzBaggage/${id}`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: [
        { type: "LzBaggageList", id: "Search" },
        { type: "LzBaggageList", id: "Details" },
      ],
    }),
    deleteLzBaggageItem: builder.mutation({
      query: (id) => ({
        url: `/LzBaggage/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "LzBaggageList", id: "Search" }],
    }),
    deliverLzBaggageItemToPassenger: builder.mutation({
      query: (formData) => {
        return {
          url: `/LzBaggage/${formData.id}/deliver`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [
        { type: "LzBaggageList", id: "Search" },
        { type: "LzBaggageList", id: "Details" },
      ],
    }),
    deliverLzBaggageItemToCharity: builder.mutation({
      query: (formData) => {
        return {
          url: `/LzBaggage/${formData.id}/deliver-to-charity`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [
        { type: "LzBaggageList", id: "Search" },
        { type: "LzBaggageList", id: "Details" },
      ],
    }),
    forwardLzBaggageItem: builder.mutation({
      query: (formData) => {
        return {
          url: `/LzBaggage/${formData.id}/forward`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [
        { type: "LzBaggageList", id: "Search" },
        { type: "LzBaggageList", id: "Details" },
      ],
    }),
    destroyLzBaggageItem: builder.mutation({
      query: (formData) => {
        return {
          url: `/LzBaggage/${formData.id}/destroy`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [
        { type: "LzBaggageList", id: "Search" },
        { type: "LzBaggageList", id: "Details" },
      ],
    }),
    revertLzBaggageItem: builder.mutation({
      query: (formData) => {
        return {
          url: `/LzBaggage/revert-to-pending`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [
        { type: "LzBaggageList", id: "Search" },
        { type: "LzBaggageList", id: "Details" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetLzBaggageListQuery,
  useExportLzBaggageItemsMutation,
  useAddLzBaggageItemMutation,
  useGetLzBaggageQuery,
  useUpdateLzBaggageItemMutation,
  useDeleteLzBaggageItemMutation,
  useDeliverLzBaggageItemToPassengerMutation,
  useDeliverLzBaggageItemToCharityMutation,
  useForwardLzBaggageItemMutation,
  useDestroyLzBaggageItemMutation,
  useRevertLzBaggageItemMutation,
} = lzBaggageListApi;
