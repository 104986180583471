import React from "react";
import { ListGroup, ListGroupItem, Button, ButtonGroup } from "reactstrap";
import { FileText, Download, Image, Eye, Trash } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";
import {
  useLazyDownloadFileQuery,
  useLazyGetSsuriFileQuery,
} from "@src/redux/general/file";
import { saveAs } from "file-saver";
import { SuccessToast } from "@src/components/shared/SuccessToast";

const FileDetailView = ({ files, category = "", onDelete }) => {
  const [downloadFile] = useLazyDownloadFileQuery();
  const [getSsuriFile] = useLazyGetSsuriFileQuery();

  const renderFilePreview = (file) => {
    if (file.type.startsWith("image")) {
      return <Image size="28" />;
    } else {
      return <FileText size="28" />;
    }
  };

  const handleView = async (identifier, fileName) => {
    const response = await getSsuriFile(
      `identifier=${identifier}&category=${category}`
    ).unwrap();

    if (response) {
      window.open(response, "_blank");
    }
  };

  const onDownload = async (identifier) => {
    const response = await downloadFile(
      `identifier=${identifier}&category=${category}`
    ).unwrap();

    const blob = new Blob([response], { type: response.type });
    saveAs(blob, identifier);
    SuccessToast(`File downloaded Successfully!`);
  };

  const fileList = files.map((file, index) => (
    <ListGroupItem
      key={`${file.identifier}-${index}`}
      className="d-flex align-items-center justify-content-between"
    >
      <div className="file-details d-flex align-items-center">
        <div className="file-preview me-1">{renderFilePreview(file)}</div>
        <div>
          <p className="file-name mb-0">{file.name}</p>
          <p className="file-size mb-0">{(file.size / 1024).toFixed(2)} KB</p>
        </div>
      </div>
      <div className="d-flex">
        <ButtonGroup className="mb-1">
          <Button
            id={`view-${index}`}
            color="secondary"
            outline
            size="sm"
            className="btn-icon"
            onClick={() => handleView(file.identifier, file.name)}
          >
            <Eye size={14} /> View
          </Button>
          <UncontrolledTooltip target={`view-${index}`} placement="top">
            View File
          </UncontrolledTooltip>

          <Button
            id={`file-${index}`}
            color="secondary"
            outline
            size="sm"
            className="btn-icon"
            onClick={() => onDownload(file.identifier)}
          >
            <Download size={14} /> Download
          </Button>
          <UncontrolledTooltip target={`file-${index}`} placement="top">
            Download
          </UncontrolledTooltip>
          {onDelete && (
            <>
              <Button
                id={`delete-${index}`}
                color="secondary"
                outline
                size="sm"
                className="btn-icon"
                onClick={() => onDelete(file)}
              >
                <Trash size={14} /> Delete
              </Button>
              <UncontrolledTooltip target={`delete-${index}`} placement="top">
                Delete File
              </UncontrolledTooltip>
            </>
          )}
        </ButtonGroup>
      </div>
    </ListGroupItem>
  ));

  return (
    <div className="mb-2">
      {files.length ? (
        <ListGroup className="my-2">{fileList}</ListGroup>
      ) : (
        <p>No files available for download.</p>
      )}
    </div>
  );
};

export default FileDetailView;
