import Logs from ".";

const LogsRoutes = [
  {
    path: "/lz-baggage/histories",
    index: true,
    element: <Logs />,
  },
  {
    path: "/lz-baggage/histories/:itemId",
    index: true,
    element: <Logs />,
  },
];

export default LogsRoutes;
