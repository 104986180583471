import WrongLoadWrongTagList from ".";
import AddWrongLoadWrongTag from "./add";
import UpdateWrongLoadWrongTag from "./update";
import Details from "./details";

const WrongLoadWrongTagRoutes = [
  {
    path: "/wrongload-wrongtag/list",
    index: true,
    element: <WrongLoadWrongTagList />,
  },
  {
    path: "/wrongload-wrongtag/list/add",
    index: true,
    element: <AddWrongLoadWrongTag />,
  },
  {
    path: "/wrongload-wrongtag/list/:id/update",
    index: true,
    element: <UpdateWrongLoadWrongTag />,
  },
  {
    path: "/wrongload-wrongtag/list/:id",
    index: true,
    element: <Details />,
  },
];

export default WrongLoadWrongTagRoutes;
