import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Button,
  Badge,
} from "reactstrap";
import { ReadonlyInput } from "@src/components/inputs";
import CopyToClipboard from "@src/components/shared/CopyToClipboard";
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";

const FoundItemDetailsModal = ({ modalOpen, toggleModal, selectedItem }) => {
  return (
    <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
      <ModalHeader toggle={toggleModal}>Found Item Details</ModalHeader>
      <ModalBody>
        {selectedItem ? (
          <>
            <Row>
              <Row className="mb-1">
                <Col tag="dt" sm="4">
                  <span className="fw-bolder mb-1">Item Type: </span>
                  <span className="mb-1">
                    {selectedItem.itemTypeName || "Other"}
                    {"    "}
                    {selectedItem.isValuable == true && (
                      <>
                        {"   "}
                        <Badge color="light-secondary" pill>
                          Valuable
                        </Badge>
                      </>
                    )}
                  </span>
                </Col>
                {selectedItem.name && (
                  <>
                    <Col tag="dt" sm="4">
                      <span className="fw-bolder mb-1">Name: </span>
                      <span className="mb-1">
                        {selectedItem.name || "Other"}
                      </span>
                    </Col>
                  </>
                )}
                {selectedItem.isValuable == true && (
                  <>
                    <Col tag="dt" sm="4">
                      <span className="fw-bolder mb-1">Amount: </span>
                      <span className="mb-1">{selectedItem.amount || "-"}</span>
                    </Col>
                    <Col tag="dt" sm="4">
                      <span className="fw-bolder mb-1">Kind: </span>
                      <span className="mb-1">{selectedItem.kind || "-"}</span>
                    </Col>
                  </>
                )}
              </Row>
              <hr></hr>
              <Col tag="dd" sm="12" className="mb-1">
                <div className="mb-1">
                  {selectedItem.description &&
                  selectedItem.description.trim() !== "" ? (
                    <>
                      <ReadonlyInput
                        label="Description"
                        name="description"
                        icon={faCommentAlt}
                        type="textarea"
                        rows="5"
                        defaultValue={selectedItem.description}
                      />
                      <CopyToClipboard
                        value={selectedItem.description}
                        defaultMsg="Click to copy"
                        successMsg="Copied to clipboard!"
                      />
                    </>
                  ) : (
                    <span>No description available</span>
                  )}
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <p>No details available.</p>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="warning" onClick={toggleModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FoundItemDetailsModal;
