import React, { Fragment, useState, memo } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  InputGroup,
  Button,
  UncontrolledTooltip,
  Badge,
} from "reactstrap";
import { Tag } from "primereact/tag";
import { useGetFoundItemQuery } from "@src/redux/found-item/list";
import { useToggleOwnerMutation } from "@src/redux/found-item/possible-owners";
import { useParams } from "react-router-dom";
import Loader from "@src/components/shared/Loader";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faPlane,
  faClipboardQuestion,
  faForward,
  faHammer,
  faTruck,
  faPersonCircleQuestion,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import FoundItemDetailsModal from "./FoundItemDetailsModal";
import { Eye, PhoneCall, UserCheck, UserX } from "react-feather";
import ActionToolbar from "./ActionToolbar";
import ConnectOwnerModal from "./ConnectOwnerModal";
import OwnerDetailsModal from "./OwnerDetailsModal";
import ConfirmSweetAlert from "@src/components/shared/ConfirmSweetAlert";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import { Button as PrimButton } from "primereact/button";
import SearchOwnerModal from "./SearchOwnerModal";

const FoundItemDetails = () => {
  const { id } = useParams();
  const { data, isFetching, error } = useGetFoundItemQuery(id);
  const [toggleOwner, { isLoading }] = useToggleOwnerMutation();
  const [activeTab, setActiveTab] = useState("1");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalConnectOpen, setModalConnectOpen] = useState(false);
  const [modalDetailsOpen, setModalDetailsOpen] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [modalSearchOpen, setModalSearchOpen] = useState(false); // State to control SearchOwnerModal

  const handleSearchOwner = () => {
    setModalSearchOpen(true);
  };

  const handleConnectOwner = (owner) => {
    setSelectedOwner(owner);
    setModalConnectOpen(true);
  };

  const handleShowDetails = (owner) => {
    setSelectedOwner(owner);
    setModalDetailsOpen(true);
  };

  const handleToggleOwner = async (owner) => {
    ConfirmSweetAlert({
      title: `Are you sure you want to set (${owner.passengerName}) as Owner?`,
      text: "This will uncheck other possible owners.",
      icon: "question",
      confirmButtonText: "Set",
      showCancelButton: true,
      cancelButtonClasses: "btn btn-warning ms-1",
      cancelButtonText: "Cancel",
      onConfirm: async () => {
        await toggleOwner({
          possibleOwnerId: owner.id,
          foundItemId: id,
        })
          .unwrap()
          .then((fulfilled) => {
            SuccessToast(
              `(${owner.passengerName}) has been set as Owner successfully!`
            );
            toggle(false);
          })
          .catch((rejected) => {});
      },
    });
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  if (isFetching) {
    return <Loader />;
  }

  if (error) {
    return <p>Error loading found item details: {error.message}</p>;
  }

  const getStatusSeverity = (status) => {
    switch (status) {
      case "Pending":
        return "warning";
      case "Delivered":
        return "success";
      case "Destroyed":
        return "danger";
      default:
        return "info"; //Forwarded
    }
  };

  const toggleModal = (item) => {
    setSelectedItem(item);
    setModalOpen(!modalOpen);
  };

  return (
    <Fragment>
      <BreadCrumbs pageName="foundItemDetails" pageTitle="Found Item Details" />
      {data ? (
        <Row>
          <Col lg="3" md="3" xs="12">
            <Card>
              <CardHeader className="border-bottom">
                <CardTitle tag="h1" className="fw-bolder">
                  <strong>
                    <span className="text-primary">
                      <FontAwesomeIcon icon={faClipboardQuestion} />
                    </span>{" "}
                    <span className="fw-bolder mb-25">Found Item Details</span>
                  </strong>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="info-container">
                  <br />
                  <Row>
                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Flight Number:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      <span className="text-primary">
                        <FontAwesomeIcon icon={faPlane} />
                      </span>{" "}
                      {data?.flightNumber}
                    </Col>
                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Flight Date:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      {data.flightDate}
                    </Col>
                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Received Date:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      {data.receivedDate}
                    </Col>
                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Status:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      <InputGroup>
                        <Tag
                          value={data.status}
                          severity={getStatusSeverity(data.status)}
                          className="text-capitalize"
                        />
                      </InputGroup>
                    </Col>
                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Seat Number:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      {data.seatNumber || "-"}
                    </Col>
                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      From Sector:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      {data.fromSector}
                    </Col>
                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      To Sector:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      {data.toSector}
                    </Col>
                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Booking Reference:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      {data.bookingReference || "-"}
                    </Col>
                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Passenger Name:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      {data.passengerName || "-"}
                    </Col>
                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Contact Info:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      {data.contactInfo || "-"}
                    </Col>
                  </Row>
                  <Row>
                    <hr />
                    <Col tag="dt" sm="12" className="fw-bolder mb-1">
                      Comments:
                    </Col>
                    <Col tag="dt" sm="12" className="mb-1">
                      {data.comments ? data.comments : "No comments available."}{" "}
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="9" md="9" xs="12">
            <ActionToolbar item={data} />
            <Nav tabs>
              <NavItem>
                <NavLink
                  active={activeTab === "1"}
                  onClick={() => toggleTab("1")}
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="me-1" />
                  Item Details
                </NavLink>
              </NavItem>
              {data.status == "Delivered" && (
                <NavItem>
                  <NavLink
                    active={activeTab === "2"}
                    onClick={() => toggleTab("2")}
                  >
                    <FontAwesomeIcon icon={faTruck} className="me-1" />
                    Delivery Info
                  </NavLink>
                </NavItem>
              )}
              {data.status == "Forwarded" && (
                <NavItem>
                  <NavLink
                    active={activeTab === "3"}
                    onClick={() => toggleTab("3")}
                  >
                    <FontAwesomeIcon icon={faForward} className="me-1" />
                    Forward Info
                  </NavLink>
                </NavItem>
              )}
              {data.status == "Destroyed" && (
                <NavItem>
                  <NavLink
                    active={activeTab === "4"}
                    onClick={() => toggleTab("4")}
                  >
                    <FontAwesomeIcon icon={faHammer} className="me-1" />
                    Destroy Info
                  </NavLink>
                </NavItem>
              )}
              {data.status == "Pending" && (
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      active={activeTab === "5"}
                      onClick={() => toggleTab("5")}
                    >
                      <FontAwesomeIcon
                        icon={faPersonCircleQuestion}
                        className="me-1"
                      />
                      Possible Owners
                    </NavLink>
                  </NavItem>
                </Nav>
              )}
            </Nav>

            <TabContent activeTab={activeTab} className="py-2">
              <TabPane tabId="1">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Found Items</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Table responsive bordered>
                      <thead>
                        <tr>
                          <th className="text-capitalize">#</th>
                          <th className="text-capitalize">Item Type</th>
                          <th className="text-capitalize">Name</th>
                          <th className="text-capitalize">Is Valuable</th>
                          <th className="text-capitalize">Kind</th>
                          <th className="text-capitalize">Amount</th>
                          <th className="text-capitalize">#</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.itemsFound.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.itemTypeName || "Other"}</td>
                            <td>{item.name || "-"}</td>
                            <td>{item.isValuable == false ? "No" : "Yes"}</td>
                            <td>{item.kind || "-"}</td>
                            <td>{item.amount !== null ? item.amount : "-"}</td>
                            <td>
                              <Button.Ripple
                                className="btn-icon"
                                color="flat-dark"
                                id={`detail-${index}`}
                                onClick={() => toggleModal(item)}
                              >
                                <Eye className="font-medium-3 text-body mx-1" />
                              </Button.Ripple>
                              <UncontrolledTooltip
                                target={`detail-${index}`}
                                placement="top"
                              >
                                More Found Item Details
                              </UncontrolledTooltip>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </TabPane>
              {data.status == "Delivered" && (
                <TabPane tabId="2">
                  {data.deliveredDetail ? (
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">Delivery Details</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col tag="dt" sm="3" className="fw-bolder mb-1">
                            Recipient Name:
                          </Col>
                          <Col tag="dd" sm="9" className="mb-1">
                            {data.deliveredDetail.recipientName}
                          </Col>
                          <Col tag="dt" sm="3" className="fw-bolder mb-1">
                            ID Number:
                          </Col>
                          <Col tag="dd" sm="9" className="mb-1">
                            {data.deliveredDetail.idNumber}
                          </Col>
                          <Col tag="dt" sm="3" className="fw-bolder mb-1">
                            Delivery Date:
                          </Col>
                          <Col tag="dd" sm="9" className="mb-1">
                            {data.deliveredDetail.deliveryDate}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  ) : (
                    <p>No delivery information available.</p>
                  )}
                </TabPane>
              )}
              {data.status == "Forwarded" && (
                <TabPane tabId="3">
                  {data.forwardedDetail ? (
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">Forward Details</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col tag="dt" sm="3" className="fw-bolder mb-1">
                            Flight Number:
                          </Col>
                          <Col tag="dd" sm="9" className="mb-1">
                            <span className="text-primary">
                              <FontAwesomeIcon icon={faPlane} />
                            </span>{" "}
                            {data.forwardedDetail.flightNumber}
                          </Col>
                          <Col tag="dt" sm="3" className="fw-bolder mb-1">
                            Flight Date:
                          </Col>
                          <Col tag="dd" sm="9" className="mb-1">
                            {data.forwardedDetail.flightDate}
                          </Col>
                          <Col tag="dt" sm="3" className="fw-bolder mb-1">
                            Handover Number:
                          </Col>
                          <Col tag="dd" sm="9" className="mb-1">
                            {data.forwardedDetail.handoverNumber}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  ) : (
                    <p>No Forward information available.</p>
                  )}
                </TabPane>
              )}
              {data.status == "Destroyed" && (
                <TabPane tabId="4">
                  {data.destroyDetail ? (
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">Destroy Details</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col tag="dt" sm="3" className="fw-bolder mb-1">
                            Destroy Date:
                          </Col>
                          <Col tag="dd" sm="9" className="mb-1">
                            {data.destroyDetail.destroyDate}
                          </Col>
                          <Col tag="dt" sm="3" className="fw-bolder mb-1">
                            Comments:
                          </Col>
                          <Col tag="dd" sm="9" className="mb-1">
                            {data.destroyDetail.comments ? (
                              <span>{data.destroyDetail.comments}</span>
                            ) : (
                              <span>No comments available.</span>
                            )}{" "}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  ) : (
                    <p>No Destroy information available.</p>
                  )}
                </TabPane>
              )}
              {data.status == "Pending" && (
                <TabPane tabId="5">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Possible Owners</CardTitle>
                      <PrimButton
                        disabled={!data.flightDate || !data.flightNumber}
                        outlined
                        type="button"
                        severity="secondary"
                        onClick={handleSearchOwner}
                      >
                        <FontAwesomeIcon
                          icon={faSearch}
                          className="font-medium-3"
                        />
                        &nbsp; Search for owner
                      </PrimButton>
                    </CardHeader>
                    <CardBody>
                      {data.possibleOwners && data.possibleOwners.length > 0 ? (
                        <Table responsive bordered>
                          <thead>
                            <tr>
                              <th className="text-capitalize">#</th>
                              <th className="text-capitalize">Seat Number</th>
                              <th className="text-capitalize">
                                Passenger Name
                              </th>
                              <th className="text-capitalize">
                                Booking Reference
                              </th>
                              <th className="text-capitalize">Email</th>
                              <th className="text-capitalize">Phone</th>
                              <th className="text-capitalize">Is Owner</th>
                              <th className="text-capitalize">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.possibleOwners.map((owner, index) => (
                              <tr key={owner.id}>
                                <td>{index + 1}</td>
                                <td>{owner.seatNumber}</td>
                                <td>{owner.passengerName}</td>
                                <td>{owner.bookingReference}</td>
                                <td>{owner.email}</td>
                                <td className="text-nowrap">
                                  {owner.homePhone || owner.workPhone}
                                </td>
                                <td>{owner.isOwner ? "Yes" : "No"}</td>
                                <td className="ps-0 pe-0">
                                  <div
                                    className="d-flex"
                                    style={{ justifyContent: "center" }}
                                  >
                                    <Button.Ripple
                                      className="btn-icon p-0"
                                      color="flat-dark"
                                      id={`connect-${owner.id}`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleConnectOwner(owner);
                                      }}
                                    >
                                      {owner.actions.length > 0 ? (
                                        <Badge
                                          pill
                                          color="primary"
                                          className="ms-1"
                                        >
                                          {owner.actions.length}
                                        </Badge>
                                      ) : (
                                        <></>
                                      )}

                                      <PhoneCall className="font-medium-3 text-body mx-1" />
                                    </Button.Ripple>
                                    <UncontrolledTooltip
                                      target={`connect-${owner.id}`}
                                      placement="top"
                                    >
                                      Connect with Owner
                                    </UncontrolledTooltip>

                                    <Button.Ripple
                                      className="btn-icon p-0"
                                      color="flat-dark"
                                      id={`details-${owner.id}`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleShowDetails(owner);
                                      }}
                                    >
                                      <Eye className="font-medium-3 text-body mx-1" />
                                    </Button.Ripple>
                                    <UncontrolledTooltip
                                      target={`details-${owner.id}`}
                                      placement="top"
                                    >
                                      Show Owner Details
                                    </UncontrolledTooltip>
                                    <Button.Ripple
                                      className="btn-icon p-0"
                                      color="flat-dark"
                                      id={`toggle-${owner.id}`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleToggleOwner(owner);
                                      }}
                                    >
                                      {owner.isOwner ? (
                                        <UserX className="font-medium-3 text-body mx-1" />
                                      ) : (
                                        <UserCheck className="font-medium-3 text-body mx-1" />
                                      )}
                                    </Button.Ripple>
                                    <UncontrolledTooltip
                                      target={`toggle-${owner.id}`}
                                      placement="top"
                                    >
                                      {owner.isOwner
                                        ? "Reset Owner"
                                        : "Set As Owner"}
                                    </UncontrolledTooltip>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ) : (
                        <p>No possible owners available.</p>
                      )}
                    </CardBody>
                  </Card>
                </TabPane>
              )}
            </TabContent>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <p>No data available.</p>
          </Col>
        </Row>
      )}
      <FoundItemDetailsModal
        modalOpen={modalOpen}
        toggleModal={() => setModalOpen(!modalOpen)}
        selectedItem={selectedItem}
      />
      <ConnectOwnerModal
        isOpen={modalConnectOpen}
        toggle={() => setModalConnectOpen(!modalConnectOpen)}
        possibleOwner={selectedOwner}
        foundItemId={data.id}
      />
      <OwnerDetailsModal
        isOpen={modalDetailsOpen}
        toggle={() => setModalDetailsOpen(!modalDetailsOpen)}
        owner={selectedOwner}
      />
      <SearchOwnerModal
        isOpen={modalSearchOpen}
        toggle={() => setModalSearchOpen(!modalSearchOpen)}
        flightNumber={data.flightNumber}
        flightDate={data.flightDate}
        foundItemId={data.id}
        possibleOwners={data.possibleOwners}
      />
    </Fragment>
  );
};

export default memo(FoundItemDetails);
