import {
  faCartPlus,
  faFileLines,
  faCartArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, UncontrolledTooltip } from "reactstrap";

const actionsBodyTemplate = (
  rowData,
  handleUpdateQuantityClick,
  handleQuantitySettlementClick
) => {
  return (
    <div className="d-flex" style={{ justifyContent: "space-between" }}>
      <Button
        style={{ padding: ".515rem .536rem" }}
        id="logs"
        className="btn-icon rounded-circle ms-1"
        outline
        color="dark"
        href={`/baggage-management/stock/histories/${rowData.id}`}
      >
        <span className="text-uppercase">
          <FontAwesomeIcon icon={faFileLines} className="font-medium-3" />
        </span>
      </Button>
      <UncontrolledTooltip target="logs" placement="top">
        Item Histories
      </UncontrolledTooltip>
      <Button
        style={{ padding: ".515rem .536rem" }}
        id="settlement"
        className="btn-icon rounded-circle ms-1"
        outline
        color="secondary"
        onClick={() => {
          handleQuantitySettlementClick({ ...rowData });
        }}
      >
        <span className="text-uppercase">
          <FontAwesomeIcon icon={faCartArrowDown} className="font-medium-3" />
        </span>
      </Button>
      <UncontrolledTooltip target="settlement" placement="top">
        Quantity Adjustment
      </UncontrolledTooltip>
      <Button
        style={{ padding: ".515rem .536rem" }}
        id="add"
        className="btn-icon rounded-circle ms-1"
        outline
        color="primary"
        onClick={() => {
          handleUpdateQuantityClick({ ...rowData });
        }}
      >
        <span className="text-uppercase">
          <FontAwesomeIcon icon={faCartPlus} className="font-medium-3" />
        </span>
      </Button>
      <UncontrolledTooltip target="add" placement="top">
        Add Quantity
      </UncontrolledTooltip>
    </div>
  );
};

const availableInBoxesTemplate = (rowData) => {
  return (
    <>
      {rowData.availableInBoxes < rowData.minimumBoxLimit ? (
        <div className="avatar p-0 m-0 bg-light-danger fw-bolder">
          <div className="avatar-content"> {rowData.availableInBoxes}</div>
        </div>
      ) : (
        <span className="text-capitalize">{rowData.availableInBoxes}</span>
      )}
    </>
  );
};

const ItemColumns = (
  handleUpdateQuantityClick,
  handleQuantitySettlementClick
) => {
  return [
    {
      header: "#",
      filter: false,
      field: "serialNumber",
      sortable: false,
      bodyStyle: { width: "5%" },
      body: (rowData, options) => options.rowIndex + 1,
    },
    {
      header: "Name",
      filter: true,
      field: "name",
      sortable: false,
      bodyStyle: { width: "15%", textAlign: "center" },
    },
    {
      header: "Available Pieces",
      filter: true,
      field: "availablePieces",
      sortable: false,
      dataType: "numeric",
      bodyStyle: { width: "15%", textAlign: "center" },
    },
    {
      header: "Available In Boxes",
      filter: true,
      field: "availableInBoxes",
      sortable: false,
      dataType: "numeric",
      bodyStyle: { width: "15%", textAlign: "center" },
      body: availableInBoxesTemplate,
    },
    {
      header: (
        <>
          Total Weight<span className="font-small-2 text-muted"> (kg)</span>
        </>
      ),
      filter: true,
      field: "totalWeight",
      sortable: false,
      dataType: "numeric",
      bodyStyle: { width: "15%", textAlign: "center" },
      body: (rowData) => {
        const weight = rowData.totalWeight;
        return Number.isInteger(weight) ? weight : weight.toFixed(2);
      },
    },
    {
      header: "Pieces Per Box",
      filter: true,
      field: "piecesPerBox",
      sortable: false,
      dataType: "numeric",
      bodyStyle: { width: "15%", textAlign: "center" },
    },
    {
      header: "Minimum Box Limit",
      filter: true,
      field: "minimumBoxLimit",
      sortable: false,
      dataType: "numeric",
      bodyStyle: { width: "15%", textAlign: "center" },
    },
    {
      header: "Actions",
      filter: false,
      field: "actions",
      bodyStyle: { width: "10%", textAlign: "center" },
      body: (rowData) =>
        actionsBodyTemplate(
          rowData,
          handleUpdateQuantityClick,
          handleQuantitySettlementClick
        ),
    },
  ];
};

export default ItemColumns;
