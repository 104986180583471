import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Row,
  Col,
  Input,
} from "reactstrap";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TextInput, RadioInput } from "@src/components/inputs";
import LoadingButton from "@src/components/shared/LoadingButton";
import { useSearchFlightPassengersMutation } from "@src/redux/found-item/passengers";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane, faCalendar } from "@fortawesome/free-solid-svg-icons";
import { formatDateOnly } from "@src/utility/Utils";
import { useAddPossibleOwnersMutation } from "@src/redux/found-item/possible-owners";
import { SuccessToast } from "@src/components/shared/SuccessToast";

const SearchOwnerModal = ({
  isOpen,
  toggle,
  flightNumber,
  flightDate,
  foundItemId,
  possibleOwners,
}) => {
  const searchCriteriaOptions = [
    { label: "Passenger Seats", value: "passengerSeats" },
    { label: "Rows", value: "rows" },
    { label: "Passenger Name", value: "passengerNameSearch" },
  ];

  const [searchPassengers, { data: passengers = [], isLoading }] =
    useSearchFlightPassengersMutation();
  const [addPossibleOwners, { isLoading: isLoadingAddPossibleOwners }] =
    useAddPossibleOwnersMutation();
  const [selectedPassengers, setSelectedPassengers] = useState([]);

  const searchOwnerSchema = yup
    .object()
    .shape({
      flightNumber: yup.string().required("Flight number is required"),
      flightDate: yup.date().required("Flight date is required"),
      passengerSeats: yup.string(),
      rows: yup.string(),
      passengerNameSearch: yup.string(),
    })
    .test(
      "at-least-one",
      "At least one of Passenger Seats, Rows, or Passenger Name must be provided",
      (values) =>
        values.passengerSeats || values.rows || values.passengerNameSearch
    );

  const formMethods = useForm({
    resolver: yupResolver(searchOwnerSchema),
    mode: "onSubmit",
    defaultValues: {
      flightNumber,
      flightDate,
      passengerSeats: "",
      rows: "",
      passengerNameSearch: "",
      searchCriteria: "passengerSeats",
    },
  });

  useEffect(() => {
    if (isOpen) {
      formMethods.reset({
        flightNumber,
        flightDate,
        passengerSeats: "",
        rows: "",
        passengerNameSearch: "",
        searchCriteria: "passengerSeats",
      });
    }
  }, [isOpen, formMethods, flightNumber, flightDate]);

  const handleFormSubmit = async (formData) => {
    const searchParams = {
      flightNumber: formData.flightNumber,
      flightDate: format(new Date(formData.flightDate), "yyyy-MM-dd"),
      [formData.searchCriteria]: formData[formData.searchCriteria] || null,
    };

    await searchPassengers(searchParams)
      .unwrap()
      .then((fulfilled) => {})
      .catch((rejected) => {});
  };

  const handleCheckboxChange = (passenger) => {
    if (
      possibleOwners.some(
        (owner) => owner.passengerId === passenger.passengerId
      )
    ) {
      return;
    }
    setSelectedPassengers((prev) =>
      prev.includes(passenger)
        ? prev.filter((p) => p.passengerId !== passenger.passengerId)
        : [...prev, passenger]
    );
  };

  const handleSave = async () => {
    if (passengers.length == 0) return;
    const newOwners = selectedPassengers.filter(
      (passenger) =>
        !possibleOwners.some(
          (owner) => owner.passengerId === passenger.passengerId
        )
    );
    if (newOwners.length > 0) {
      await addPossibleOwners({
        foundItemId: foundItemId,
        possibleOwners: newOwners.map((p) => ({
          seatNumber: p.unitDesignator,
          bookingReference: p.bookingReference,
          passengerName: `${p.firstName} ${p.lastName}`,
          passengerId: p.passengerId,
          homePhone: p.homePhone,
          workPhone: p.workPhone,
          email: p.emailAddress,
          comments: "",
          isOwner: false,
        })),
      })
        .unwrap()
        .then((fulfilled) => {
          SuccessToast("Passengers added as possible owners successfully!");
          toggle(false);
        })
        .catch((rejected) => {});
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl" className="p-fluid">
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>
            <div>
              Search for Owner in flight{" "}
              <span className="text-primary">
                <FontAwesomeIcon icon={faPlane} />
              </span>{" "}
              XY <span className="fw-bolder">{flightNumber} </span> On{" "}
              <span className="fw-bolder">
                {" "}
                <span className="text-primary">
                  <FontAwesomeIcon icon={faCalendar} />
                </span>{" "}
                {flightDate && formatDateOnly(new Date(flightDate))}
              </span>
            </div>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md={4}>
                <div className="mb-1">
                  <RadioInput
                    twoLines
                    label=""
                    name="searchCriteria"
                    options={searchCriteriaOptions}
                    optionKey="value"
                    optionLabel="label"
                    horizontalAlignment={true}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-1">
                  <TextInput
                    twoLines={true}
                    name={formMethods.watch("searchCriteria")}
                    placeholder={`Enter ${
                      searchCriteriaOptions.find(
                        (opt) =>
                          opt.value === formMethods.watch("searchCriteria")
                      )?.label
                    }`}
                  />
                </div>
              </Col>
              <Col md={12} className="justify-content-end d-flex mb-2">
                <LoadingButton
                  outline
                  loading={isLoading}
                  type="submit"
                  color="dark"
                >
                  Search For Pax
                </LoadingButton>
              </Col>
              <hr />
              <Col md="12">
                <h5 className="mb-1 fw-bolder">Passengers</h5>
                <Table bordered responsive className="mb-2">
                  <thead>
                    <tr>
                      <th className="text-capitalize">Select</th>
                      <th className="text-capitalize">Seat Number</th>
                      <th className="text-capitalize">Passenger Name</th>
                      <th className="text-capitalize">Booking Reference</th>
                      <th className="text-capitalize">Email</th>
                      <th className="text-capitalize">Phone</th>
                    </tr>
                  </thead>
                  <tbody>
                    {passengers.length > 0 ? (
                      passengers.map((passenger, index) => {
                        const isAdded = possibleOwners.some(
                          (owner) => owner.passengerId === passenger.passengerId
                        );
                        return (
                          <tr key={index}>
                            <td className="text-center">
                              {isAdded ? (
                                <span className="ms-1 text-capitalize badge bg-light-secondary rounded-pill">
                                  Added
                                </span>
                              ) : (
                                <Input
                                  type="checkbox"
                                  checked={selectedPassengers.includes(
                                    passenger
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(passenger)
                                  }
                                />
                              )}
                            </td>
                            <td>{passenger.unitDesignator}</td>
                            <td>{`${passenger.firstName} ${passenger.lastName}`}</td>
                            <td>{passenger.bookingReference}</td>
                            <td>{passenger.emailAddress}</td>
                            <td className="text-nowrap">
                              {passenger.homePhone || passenger.workPhone}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          No passengers found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <LoadingButton
              loading={isLoadingAddPossibleOwners}
              type="button"
              color="primary"
              onClick={handleSave}
              disabled={passengers.length == 0}
            >
              Save Selected
            </LoadingButton>
            <Button type="button" color="warning" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default SearchOwnerModal;
