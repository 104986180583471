import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Form,
} from "reactstrap";
import UploadFile from "@src/components/inputs/UploadFile";

const FileUploadModal = ({ isOpen, toggleModal, onSave, fileData }) => {
  const [files, setFiles] = useState([]);
  const [comment, setComment] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);

  useEffect(() => {
    if (fileData) {
      setFiles(fileData.file ? [fileData.file] : []);
      setComment(fileData.comment || "");
      setIsEditing(fileData.isEditing || false);
      setEditingIndex(fileData.index);
    } else {
      resetModal();
    }
  }, [fileData, isOpen]);

  const resetModal = () => {
    setFiles([]);
    setComment("");
    setIsEditing(false);
    setEditingIndex(null);
  };

  const handleSave = () => {
    if (files.length === 0) {
      alert("Please select a file.");
      return;
    }
    onSave({ file: files[0], comment, isEditing, index: editingIndex });
    resetModal();
    toggleModal();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>
        {isEditing ? "Edit File Comment" : "Add New File"}
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col md="12">
              {isEditing ? (
                <input
                  type="text"
                  className="form-control"
                  value={files.length != 0 ? files[0].name : ""}
                  readOnly
                  disabled
                />
              ) : (
                <UploadFile
                  files={files}
                  setFiles={setFiles}
                  formMethods={{
                    setValue: () => {},
                    getValues: () => {},
                  }}
                  maxFiles={1}
                />
              )}
            </Col>
            <Col md="12" className="mt-3">
              <textarea
                className="form-control"
                placeholder="Enter file comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave}>
          Save
        </Button>
        <Button color="warning" onClick={toggleModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FileUploadModal;
