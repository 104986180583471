import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  InputGroup,
} from "reactstrap";
import {
  formatDateTime,
  formatDateOnly,
  formatPascalCaseToLabel,
} from "@src/utility/Utils";
import { Tag } from "primereact/tag";

const DetailsModal = ({ isOpen, toggle, data }) => {
  const getStatusSeverity = (status) => {
    switch (status) {
      case "Pending":
        return "info";
      case "DeliveredToPassenger":
        return "success";
      case "DeliveredToCharity":
        return "primary";
      case "Destroyed":
        return "danger";
      default:
        return "warning"; //Forwarded
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case "DeliveredToPassenger":
        return "To Pax";
      case "DeliveredToCharity":
        return "To Charity";
      default:
        return formatPascalCaseToLabel(status);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Details</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={6}>
            <div className="mb-2">
              <label className="form-label">
                <strong>Status</strong>
              </label>
              <InputGroup>
                <Tag
                  value={getStatusLabel(data?.status)}
                  severity={getStatusSeverity(data?.status)}
                  className="text-capitalize"
                />
              </InputGroup>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-2">
              <label className="form-label">
                <strong>Created On</strong>
              </label>
              <input
                type="text"
                className="form-control"
                value={
                  data?.createdOnUtc
                    ? formatDateTime(new Date(data.createdOnUtc))
                    : "N/A"
                }
                disabled
              />
            </div>
          </Col>
        </Row>

        {/* Show fields for DeliveredToPassenger */}
        {data?.status === "DeliveredToPassenger" && (
          <>
            <Row>
              <Col md={6}>
                <div className="mb-2">
                  <label className="form-label">
                    <strong>Passenger Name</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={data.details.passengerName || "N/A"}
                    disabled
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-2">
                  <label className="form-label">
                    <strong>Passenger ID</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={data.details.passengerIdNum || "N/A"}
                    disabled
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-2">
                  <label className="form-label">
                    <strong>Delivery Date</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={
                      data.details.deliveryDate
                        ? formatDateOnly(new Date(data.details.deliveryDate))
                        : "N/A"
                    }
                    disabled
                  />
                </div>
              </Col>
            </Row>
          </>
        )}

        {/* Show fields for DeliveredToCharity */}
        {data?.status === "DeliveredToCharity" && (
          <>
            <Row>
              <Col md={6}>
                <div className="mb-2">
                  <label className="form-label">
                    <strong>Charity Name</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={data.details.charityName || "N/A"}
                    disabled
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-2">
                  <label className="form-label">
                    <strong>Charity Contact</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={data.details.charityContact || "N/A"}
                    disabled
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-2">
                  <label className="form-label">
                    <strong>Comments</strong>
                  </label>
                  <textarea
                    className="form-control"
                    value={data.details.comments || "N/A"}
                    disabled
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-2">
                  <label className="form-label">
                    <strong>Delivered Date</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={
                      data.details.deliveredDate
                        ? formatDateOnly(new Date(data.details.deliveredDate))
                        : "N/A"
                    }
                    disabled
                  />
                </div>
              </Col>
            </Row>
          </>
        )}

        {/* Show fields for Forwarded */}
        {data?.status === "Forwarded" && (
          <>
            <Row>
              <Col md={6}>
                <div className="mb-2">
                  <label className="form-label">
                    <strong>Rush Tag Number</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={data.details.rushTagNumber || "N/A"}
                    disabled
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-2">
                  <label className="form-label">
                    <strong>Flight Number</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={data.details.flightNumber || "N/A"}
                    disabled
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-2">
                  <label className="form-label">
                    <strong>Flight Date</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={
                      data.details.flightDate
                        ? formatDateOnly(new Date(data.details.flightDate))
                        : "N/A"
                    }
                    disabled
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-2">
                  <label className="form-label">
                    <strong>Destination</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={data.details.destination || "N/A"}
                    disabled
                  />
                </div>
              </Col>
            </Row>
          </>
        )}

        {/* Show fields for Destroyed */}
        {data?.status === "Destroyed" && (
          <>
            <Row>
              <Col md={6}>
                <div className="mb-2">
                  <label className="form-label">
                    <strong>Destroy Reason</strong>
                  </label>
                  <textarea
                    className="form-control"
                    value={data.details.destroyReason || "N/A"}
                    disabled
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-2">
                  <label className="form-label">
                    <strong>Comments</strong>
                  </label>
                  <textarea
                    className="form-control"
                    value={data.details.comments || "N/A"}
                    disabled
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-2">
                  <label className="form-label">
                    <strong>Destroy Date</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={
                      data.details.destroyDate
                        ? formatDateOnly(new Date(data.details.destroyDate))
                        : "N/A"
                    }
                    disabled
                  />
                </div>
              </Col>
            </Row>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="warning" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DetailsModal;
