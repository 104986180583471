import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
  InputGroup,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import { useGetLzBaggageQuery } from "@src/redux/lz-baggage/list";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import {
  formatDateOnly,
  formatDateTime,
  formatPascalCaseToLabel,
} from "@src/utility/Utils";
import FileDetailView from "@src/components/shared/FileDetailView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTag,
  faPlane,
  faFolderOpen,
  faSuitcase,
} from "@fortawesome/free-solid-svg-icons";
import ActionToolbar from "./ActionToolbar";
import { Tag } from "primereact/tag";
import DetailsModal from "./DetailsModal";
import { Eye } from "react-feather";

const LzBaggageDetailsPage = () => {
  const { id } = useParams();
  const { data, error } = useGetLzBaggageQuery(id);
  const [active, setActive] = useState("1");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const toggle = (tab) => {
    if (active !== tab) {
      setActive(tab);
    }
  };

  const getStatusSeverity = (status) => {
    switch (status) {
      case "Pending":
        return "info";
      case "DeliveredToPassenger":
        return "success";
      case "DeliveredToCharity":
        return "primary";
      case "Destroyed":
        return "danger";
      default:
        return "warning"; //Forwarded
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case "DeliveredToPassenger":
        return "To Pax";
      case "DeliveredToCharity":
        return "To Charity";
      default:
        return formatPascalCaseToLabel(status || "");
    }
  };

  const toggleModal = (item) => {
    setSelectedItem(item);
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <BreadCrumbs pageName="lzBaggageDetails" pageTitle="LZ Baggage Details" />
      {data ? (
        <Row>
          <Col lg="4" md="4" xs="12">
            <Card>
              <CardHeader className="border-bottom">
                <CardTitle tag="h1" className="fw-bolder">
                  <strong>
                    <span className="text-primary">
                      <FontAwesomeIcon icon={faSuitcase} />
                    </span>{" "}
                    <span className="fw-bolder mb-25">Lz-Baggage Details</span>
                  </strong>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="info-container">
                  <br />
                  <Row tag="dl">
                    <Col tag="dt" sm="6" className="fw-bolder  mb-1">
                      Module:
                    </Col>
                    <Col tag="dd" sm="6" className=" mb-1">
                      {data?.module}
                    </Col>
                    <Col tag="dt" sm="6" className="fw-bolder  mb-1">
                      Module Number:
                    </Col>
                    <Col tag="dd" sm="6" className=" mb-1">
                      {data?.moduleNumber}
                    </Col>
                    <Col tag="dt" sm="6" className="fw-bolder  mb-1">
                      Module Created Date:
                    </Col>
                    <Col tag="dd" sm="6" className=" mb-1">
                      {formatDateOnly(new Date(data?.moduleCreatedDate))}
                    </Col>
                    <Col tag="dt" sm="6" className="fw-bolder  mb-1">
                      Original Flight Number:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      <span className="text-primary">
                        <FontAwesomeIcon icon={faPlane} />
                      </span>{" "}
                      {data?.originalFlightNumber}
                    </Col>
                    <Col tag="dt" sm="6" className="fw-bolder  mb-1">
                      Original Flight Transit:
                    </Col>
                    <Col tag="dd" sm="6" className=" mb-1">
                      {data?.originalFlightTransit}
                    </Col>
                    <Col tag="dt" sm="6" className="fw-bolder  mb-1">
                      Destination:
                    </Col>
                    <Col tag="dd" sm="6" className=" mb-1">
                      {data?.destination}
                    </Col>
                    <Col tag="dt" sm="6" className="fw-bolder  mb-1">
                      Received Flight Number:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      <span className="text-primary">
                        <FontAwesomeIcon icon={faPlane} />
                      </span>{" "}
                      {data?.receivedFlightNumber}
                    </Col>
                    <Col tag="dt" sm="6" className="fw-bolder  mb-1">
                      Received Flight Date:
                    </Col>
                    <Col tag="dd" sm="6" className=" mb-1">
                      {formatDateOnly(new Date(data?.receivedFlightDate))}
                    </Col>
                    <Col tag="dt" sm="6" className="fw-bolder  mb-1">
                      Weight:
                    </Col>
                    <Col tag="dd" sm="6" className=" mb-1">
                      {data?.weight} kg
                    </Col>
                    <Col tag="dt" sm="6" className="fw-bolder  mb-1">
                      Status:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      <InputGroup>
                        <Tag
                          value={getStatusLabel(data.status)}
                          severity={getStatusSeverity(data.status)}
                          className="text-capitalize"
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row>
                    <hr />
                    <Col tag="dt" sm="12" className="fw-bolder mb-1">
                      Comments:
                    </Col>
                    <Col tag="dt" sm="12" className="mb-1">
                      {data?.comments
                        ? data?.comments
                        : "No comments available."}{" "}
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="8" md="8" xs="12">
            <ActionToolbar item={data} />
            <Nav tabs>
              <NavItem>
                <NavLink active={active === "1"} onClick={() => toggle("1")}>
                  <FontAwesomeIcon icon={faTag} /> Status Updates
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink active={active === "2"} onClick={() => toggle("2")}>
                  <FontAwesomeIcon icon={faFolderOpen} /> Files
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent className="py-50" activeTab={active}>
              <TabPane tabId="1">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Status Updates</CardTitle>
                  </CardHeader>
                  <CardBody>
                    {data?.statusUpdates.length > 0 ? (
                      <Table responsive>
                        <thead>
                          <tr>
                            <th className="text-capitalize">#</th>
                            <th className="text-capitalize">Status</th>
                            <th className="text-capitalize">Action Date</th>
                            <th className="text-capitalize">Comments</th>
                            <th className="text-capitalize">#</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.statusUpdates.map((update, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <InputGroup>
                                  <Tag
                                    value={getStatusLabel(update.status)}
                                    severity={getStatusSeverity(update.status)}
                                    className="text-capitalize"
                                  />
                                </InputGroup>
                              </td>
                              <td>
                                {formatDateTime(new Date(update.createdOnUtc))}
                              </td>
                              <td>{update.comments || "-"}</td>
                              <td>
                                <Button.Ripple
                                  className="btn-icon"
                                  color="flat-dark"
                                  id={`detail-${index}`}
                                  onClick={() => toggleModal(update)}
                                >
                                  <Eye className="font-medium-3 text-body mx-1" />
                                </Button.Ripple>
                                <UncontrolledTooltip
                                  target={`detail-${index}`}
                                  placement="top"
                                >
                                  More Action Details
                                </UncontrolledTooltip>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <p>No status updates available.</p>
                    )}
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="2">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Files</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <FileDetailView files={data?.files} category="LzBaggage" />
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <p>{error ? error : "No data available."}</p>
          </Col>
        </Row>
      )}
      <DetailsModal
        isOpen={modalOpen}
        toggle={toggleModal}
        data={selectedItem}
      />
    </>
  );
};

export default LzBaggageDetailsPage;
