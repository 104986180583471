import React, { useState } from "react";
import {
  Card,
  CardHeader,
  Button,
  ButtonGroup,
  UncontrolledTooltip,
} from "reactstrap";
import {
  faEdit,
  faTruck,
  faForward,
  faHammer,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import DeleteLzBaggage from "./delete";
import DeliverModal from "./deliverModel";
import ForwardModal from "./forward";
import DestroyModal from "./destroy";
import RevertLzBaggage from "./revert";

const ActionToolbar = ({ item }) => {
  const [isDeliverModalVisible, setIsDeliverModalVisible] = useState(false);
  const [isForwardModalVisible, setIsForwardModalVisible] = useState(false);
  const [isDestroyModalVisible, setIsDestroyModalVisible] = useState(false);

  if (
    item.status != "Pending" &&
    item.status != "DeliveredToCharity" &&
    item.status != "Forwarded"
  )
    return null;

  const handleDeliverToggle = () => {
    setIsDeliverModalVisible((prevState) => !prevState);
  };

  const handleForwardToggle = () => {
    setIsForwardModalVisible((prevState) => !prevState);
  };

  const handleDestroyToggle = () => {
    setIsDestroyModalVisible((prevState) => !prevState);
  };

  return (
    <>
      <Card>
        <CardHeader
          className="border-bottom p-1 flex flex-wrap gap-2"
          style={{ justifyContent: "right" }}
        >
          <ButtonGroup className="mb-1">
            {item.status == "Pending" ? (
              <>
                <DeleteLzBaggage item={item} />
                <Button
                  outline
                  tag={Link}
                  to={`/baggage-management/lz-baggage/list/${item.id}/update`}
                  type="button"
                  color="secondary"
                  id={`update-button-${item.id}`}
                >
                  <FontAwesomeIcon icon={faEdit} className="font-medium-1" />
                  &nbsp; Update
                </Button>
                <UncontrolledTooltip
                  target={`update-button-${item.id}`}
                  placement="top"
                >
                  Update Baggage
                </UncontrolledTooltip>
                <Button
                  outline
                  onClick={handleDeliverToggle}
                  type="button"
                  color="secondary"
                  id={`deliver-button-${item.id}`}
                >
                  <FontAwesomeIcon icon={faTruck} className="font-medium-1" />
                  &nbsp; Deliver
                </Button>
                <UncontrolledTooltip
                  target={`deliver-button-${item.id}`}
                  placement="top"
                >
                  Deliver
                </UncontrolledTooltip>
                <Button
                  outline
                  onClick={handleForwardToggle}
                  type="button"
                  color="secondary"
                  id={`forward-button-${item.id}`}
                >
                  <FontAwesomeIcon icon={faForward} className="font-medium-1" />
                  &nbsp; Forward
                </Button>
                <UncontrolledTooltip
                  target={`forward-button-${item.id}`}
                  placement="top"
                >
                  Forward
                </UncontrolledTooltip>
                <Button
                  outline
                  onClick={handleDestroyToggle}
                  type="button"
                  color="secondary"
                  id={`Destroy-button-${item.id}`}
                >
                  <FontAwesomeIcon icon={faHammer} className="font-medium-1" />
                  &nbsp; Destroy
                </Button>
                <UncontrolledTooltip
                  target={`Destroy-button-${item.id}`}
                  placement="top"
                >
                  Destroy
                </UncontrolledTooltip>
              </>
            ) : (
              ""
            )}

            {item.status == "DeliveredToCharity" ||
            item.status == "Forwarded" ? (
              <RevertLzBaggage item={item} />
            ) : (
              ""
            )}
          </ButtonGroup>
        </CardHeader>
      </Card>

      <DeliverModal
        isVisible={isDeliverModalVisible}
        toggle={handleDeliverToggle}
        baggageData={item}
      />
      <ForwardModal
        isVisible={isForwardModalVisible}
        toggle={handleForwardToggle}
        baggageData={item}
      />
      <DestroyModal
        isVisible={isDestroyModalVisible}
        toggle={handleDestroyToggle}
        baggageData={item}
      />
    </>
  );
};

export default ActionToolbar;
