import ItemList from ".";
import AddFoundItem from "./add";
import UpdateFoundItem from "./update";
import Details from "./details";

const ItemListRoutes = [
  {
    path: "/found-items/list",
    index: true,
    element: <ItemList />,
  },
  {
    path: "/found-items/list/add",
    index: true,
    element: <AddFoundItem />,
  },
  {
    path: "/found-items/list/:id/update",
    index: true,
    element: <UpdateFoundItem />,
  },
  {
    path: "/found-items/list/:id",
    index: true,
    element: <Details />,
  },
];

export default ItemListRoutes;
