import React from "react";
import * as yup from "yup";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Form,
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "reactstrap";
import {
  faPlane,
  faCalendar,
  faBoxes,
  faTags,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { TextInput, SelectInput, CalendarInput } from "@src/components/inputs";
import {
  useForwardLeftBehindItemMutation,
  useGetLeftBehindItemQuery,
} from "@src/redux/left-behind/list";
import { useGetStationListQuery } from "@src/redux/station/station";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import LoadingButton from "@src/components/shared/LoadingButton";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import { format } from "date-fns";

const ForwardLeftBehind = () => {
  const { id: leftBehindId } = useParams();
  const [forwardLeftBehindItem, { isLoading }] =
    useForwardLeftBehindItemMutation();
  const { data: leftBehindItem, error } =
    useGetLeftBehindItemQuery(leftBehindId);
  const { data: stationList } = useGetStationListQuery();
  const navigate = useNavigate();

  const forwardSchema = yup.object().shape({
    forwardedPieces: yup
      .number()
      .required("Number of forwarded pieces is required.")
      .positive("Number of forwarded pieces must be greater than 0.")
      .integer("Number of forwarded pieces must be an integer.")
      .test(
        "is-valid-amount",
        "Forwarded pieces must be less than or equal to remaining pieces.",
        (value) => {
          return value <= (leftBehindItem?.remainingPieces || 0);
        }
      ),
    flightNumber: yup.string().required(),
    flightDate: yup.date().required("Flight date is required."),
    destination: yup.string().required("Destination is required."),
    tags: yup.string().nullable(),
    comments: yup.string().nullable(),
  });

  const formMethods = useForm({
    resolver: yupResolver(forwardSchema),
    mode: "onSubmit",
    defaultValues: {
      forwardedPieces: 1,
      flightNumber: "",
      flightDate: "",
      destination: "",
      tags: "",
      comments: "",
    },
  });

  const handleFormSubmit = async (formData) => {
    formData.flightDate = format(new Date(formData.flightDate), "yyyy-MM-dd");
    const forwardData = {
      ...formData,
      leftBehindId,
    };

    await forwardLeftBehindItem(forwardData)
      .unwrap()
      .then((fulfilled) => {
        SuccessToast("Left-Behind item forwarded successfully!");
        navigate(`/baggage-management/left-behind/list/${leftBehindId}`);
      })
      .catch((rejected) => {});
  };

  return (
    <>
      <BreadCrumbs
        pageName="forwardLeftBehind"
        pageTitle="Forward Left-Behind"
      />

      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <Row>
            <Col lg="12" md="12" xs="12">
              <Card>
                <CardHeader className="border-bottom d-flex justify-content-between align-items-center">
                  <CardTitle tag="h1" className="fw-bolder">
                    Forward Left-Behind Item
                  </CardTitle>
                  <div>
                    <LoadingButton
                      loading={isLoading}
                      type="submit"
                      color="primary"
                      className="me-2"
                    >
                      Forward
                    </LoadingButton>
                    <Button
                      type="reset"
                      color="warning"
                      onClick={() => formMethods.reset()}
                    >
                      Reset
                    </Button>
                  </div>
                </CardHeader>

                <CardBody>
                  <div className="info-container">
                    <br />
                    <Row>
                      <Col md={4}>
                        <div className="mb-2">
                          <TextInput
                            twoLines
                            label="Flight Number"
                            name="flightNumber"
                            icon={faPlane}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-2">
                          <CalendarInput
                            twoLines={true}
                            label="Flight Date"
                            name="flightDate"
                            icon={faCalendar}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-2">
                          <SelectInput
                            twoLines
                            label="Destination Station"
                            name="destination"
                            options={
                              stationList?.map((type) => ({
                                value: type.id,
                                label: type.id,
                              })) || []
                            }
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-2">
                          <SelectInput
                            twoLines
                            label="Transit Station"
                            name="transitStation"
                            options={
                              stationList?.map((type) => ({
                                value: type.id,
                                label: type.id,
                              })) || []
                            }
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-2">
                          <TextInput
                            twoLines
                            label={
                              <>
                                Forwarded Pieces{" "}
                                <span className="text-muted">
                                  (remaining {leftBehindItem?.remainingPieces}{" "}
                                  Piece)
                                </span>
                              </>
                            }
                            placeholder="Forwarded Pieces"
                            name="forwardedPieces"
                            type="number"
                            icon={faBoxes}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-2">
                          <TextInput
                            twoLines
                            label="Tags"
                            name="tags"
                            icon={faTags}
                          />
                        </div>
                      </Col>
                      <Col md={8}>
                        <div className="mb-2">
                          <TextInput
                            twoLines
                            label="Comments"
                            name="comments"
                            type="textarea"
                            rows="5"
                            icon={faComment}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </>
  );
};

export default ForwardLeftBehind;
