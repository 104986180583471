import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Col,
  Row,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { TextInput } from "@src/components/inputs";
import { useDestroyFoundItemMutation } from "@src/redux/found-item/list";
import { faTag, faCalendar } from "@fortawesome/free-solid-svg-icons";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import LoadingButton from "@src/components/shared/LoadingButton";
import CalendarInput from "@src/components/inputs/CalendarInput";
import { format } from "date-fns";

const DestroyModal = ({ visible, toggle, item }) => {
  const [destroyItem, { isLoading }] = useDestroyFoundItemMutation();
  const destroyItemSchema = yup.object().shape({
    destroyDate: yup
      .date()
      .required()
      .max(
        new Date(new Date().setHours(0, 0, 0, 0)),
        "Destroy date cannot be in the future (UTC)"
      ),
    comments: yup.string().nullable(),
  });

  const formMethods = useForm({
    resolver: yupResolver(destroyItemSchema),
    mode: "onSubmit",
    defaultValues: {
      comments: "",
    },
  });

  useEffect(() => {
    if (visible) {
      formMethods.reset({
        destroyDate: "",
        comments: "",
      });
    }
  }, [visible, formMethods]);

  const handleFormSubmit = async (formData) => {
    await destroyItem({
      id: item.id,
      destroyDate: format(new Date(formData.destroyDate), "yyyy-MM-dd"),
      comments: formData.comments,
    })
      .unwrap()
      .then((fulfilled) => {
        SuccessToast("Found Item Destroyed successfully!");
        toggle(false);
      })
      .catch((rejected) => {});
  };

  return (
    <Modal isOpen={visible} toggle={toggle} className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>Destroy Found Item</ModalHeader>
          <ModalBody>
            <Row>
              <Col md={12}>
                <div className="mb-1">
                  <CalendarInput
                    twoLines={true}
                    label="Destroy Date"
                    name="destroyDate"
                    icon={faCalendar}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-1">
                  <TextInput
                    twoLines={true}
                    label="Comments"
                    name="comments"
                    icon={faTag}
                    type="textarea"
                    rows="5"
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <LoadingButton loading={isLoading} type="submit" color="primary">
              Save
            </LoadingButton>
            <Button type="button" color="warning" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default DestroyModal;
