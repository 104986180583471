import { Calendar } from "primereact/calendar";
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";
import { formatDateTime } from "@src/utility/Utils";
import {
  faEye,
  faClockRotateLeft,
  faCheck,
  faXmark,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, UncontrolledTooltip } from "reactstrap";

const orderStatuses = ["pending", "approved", "rejected", "reversed"];

const getStatusSeverity = (status) => {
  switch (status) {
    case "pending":
      return "info";
    case "approved":
      return "success";
    case "rejected":
      return "danger";
    default:
      return "warning";
  }
};

const OrderColumns = (
  handleRejectOrderClick,
  handleReverseOrderClick,
  tags
) => {
  const createdOnUtcBodyTemplate = (rowData) => {
    return formatDateTime(new Date(rowData.createdOnUtc));
  };

  const createdOnUtcFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value ? new Date(options.value) : null}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="dd/mm/yy"
        placeholder="dd/mm/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.status}
        severity={getStatusSeverity(rowData.status)}
        className="text-capitalize"
      />
    );
  };

  const statusItemTemplate = (option) => {
    return (
      <span className="text-capitalize">
        <Tag value={option} severity={getStatusSeverity(option)} />
      </span>
    );
  };

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={orderStatuses}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={statusItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };
  const CommentsItemTemplate = (comments, maxLength = 75) => {
    if (!comments || comments.trim() === "") {
      return "";
    }
    if (comments.length > maxLength) {
      const truncated = comments.slice(0, maxLength);
      const remainingWords = comments
        .slice(maxLength)
        .trim()
        .split(/\s+/).length;
      return `${truncated}... (${remainingWords} more words)`;
    }
    return comments;
  };

  const CommentBodyTemplate = ({ comments }) => (
    <span>{CommentsItemTemplate(comments)}</span>
  );

  const actionsBodyTemplate = (
    row,
    handleRejectOrderClick,
    handleReverseOrderClick
  ) => {
    return (
      <div className="d-flex" style={{ justifyContent: "space-between" }}>
        <a
          style={{ padding: ".515rem .536rem" }}
          id="details"
          outline
          className="btn  btn-icon rounded-circle btn-outline-dark"
          color="primary"
          href={`/baggage-management/stock/orders/${row.id}`}
          target="_self"
        >
          {" "}
          <FontAwesomeIcon icon={faEye} className="font-medium-3" />
        </a>
        <UncontrolledTooltip target="details" placement="top">
          Details
        </UncontrolledTooltip>
        {row.status == "approved" ? (
          <>
            <Button
              style={{ padding: ".515rem .536rem" }}
              id="reverse"
              className="btn-icon rounded-circle ms-1"
              outline
              color="warning"
              onClick={() => {
                handleReverseOrderClick({ ...row });
              }}
            >
              <span className="text-uppercase">
                <FontAwesomeIcon
                  icon={faClockRotateLeft}
                  className="font-medium-3"
                />
              </span>
            </Button>
            <UncontrolledTooltip target="reverse" placement="top">
              Reverse
            </UncontrolledTooltip>
          </>
        ) : (
          ""
        )}
        {row.status == "pending" ? (
          <>
            <Button
              style={{ padding: ".515rem .536rem" }}
              id="update"
              className="btn-icon rounded-circle ms-1"
              outline
              color="dark"
              href={`/baggage-management/stock/orders/${row.id}/update`}
            >
              <span className="text-uppercase">
                <FontAwesomeIcon icon={faEdit} className="font-medium-3" />
              </span>
            </Button>
            <UncontrolledTooltip target="update" placement="top">
              Update
            </UncontrolledTooltip>
            <Button
              style={{ padding: ".515rem .536rem" }}
              id="reject"
              className="btn-icon rounded-circle ms-1"
              outline
              color="danger"
              onClick={() => {
                handleRejectOrderClick({ ...row });
              }}
            >
              <span className="text-uppercase">
                <FontAwesomeIcon icon={faXmark} className="font-medium-3" />
              </span>
            </Button>
            <UncontrolledTooltip target="reject" placement="top">
              Reject
            </UncontrolledTooltip>
            <Button
              style={{ padding: ".515rem .536rem" }}
              id="approve"
              className="btn-icon rounded-circle ms-1"
              outline
              color="primary"
              href={`/baggage-management/stock/orders/${row.id}/approve`}
            >
              <span className="text-uppercase">
                <FontAwesomeIcon icon={faCheck} className="font-medium-3" />
              </span>
            </Button>
            <UncontrolledTooltip target="approve" placement="top">
              Approve
            </UncontrolledTooltip>
          </>
        ) : (
          ""
        )}
      </div>
    );
  };

  const tagTypeItemTemplate = (option) => {
    return <span className="text-capitalize">{option}</span>;
  };

  const tagTypeFilterTemplate = (options, tags) => {
    return (
      <Dropdown
        value={options.value}
        options={tags?.map((item) => item.name)}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={tagTypeItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  return [
    {
      header: "Order Number",
      filter: true,
      field: "id",
      sortable: false,
      dataType: "numeric",
      bodyStyle: { width: "15%" },
    },
    {
      header: "Station",
      filter: true,
      field: "station",
      sortable: false,
      bodyStyle: { width: "10%" },
    },
    {
      header: "Order Tag Type",
      filter: true,
      field: "orderTagTypeName",
      sortable: false,
      filterField: "orderTagType.name",
      filterElement: (options) => tagTypeFilterTemplate(options, tags),
      showFilterMatchModes: false,
      bodyStyle: { width: "10%" },
    },
    {
      header: (
        <>
          Total Weight<span className="font-small-2 text-muted"> (kg)</span>
        </>
      ),
      filter: true,
      field: "totalWeight",
      sortable: false,
      dataType: "numeric",
      bodyStyle: { textAlign: "center", overflow: "visible" },
      bodyStyle: { width: "15%" },
    },
    {
      header: "Status",
      filter: true,
      field: "status",
      sortable: false,
      body: statusBodyTemplate,
      filterElement: statusFilterTemplate,
      showFilterMatchModes: false,
      bodyStyle: { width: "10%", textAlign: "center", overflow: "visible" },
    },
    {
      header: (
        <>
          Created Time<span className="font-small-2 text-muted"> (UTC)</span>
        </>
      ),
      filter: true,
      field: "createdOnUtc",
      sortable: false,
      dataType: "date",
      body: createdOnUtcBodyTemplate,
      filterElement: createdOnUtcFilterTemplate,
      filterField: "createdOnUtc.date",
      bodyStyle: { width: "20%" },
    },
    {
      header: "Comments",
      filter: false,
      field: "comments",
      sortable: false,
      body: CommentBodyTemplate,
      bodyStyle: { width: "25%" },
    },
    {
      header: "Actions",
      filter: false,
      field: "actions",
      headerStyle: { textAlign: "center" },
      bodyStyle: { width: "10%", textAlign: "center" },
      body: (rowData) =>
        actionsBodyTemplate(
          rowData,
          handleRejectOrderClick,
          handleReverseOrderClick
        ),
    },
  ];
};

export default OrderColumns;
