import React, { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { useGetLeftBehindItemQuery } from "@src/redux/left-behind/list";
import FileDetailView from "@src/components/shared/FileDetailView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faForward,
  faPlane,
  faCartFlatbedSuitcase,
  faFolderOpen,
} from "@fortawesome/free-solid-svg-icons";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import { formatDateOnly } from "@src/utility/Utils";
import { Eye } from "react-feather";
import ForwardDetailsModal from "./ForwardDetailsModal";
import ActionToolbar from "./ActionToolbar";

const LeftBehindDetailsPage = () => {
  const { id } = useParams();
  const { data, error } = useGetLeftBehindItemQuery(id);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedForward, setSelectedForward] = useState(null);
  const [active, setActive] = useState("1");

  const toggle = (tab) => {
    if (active !== tab) {
      setActive(tab);
    }
  };

  const toggleModal = (forward) => {
    setSelectedForward(forward);
    setModalOpen(!modalOpen);
  };
  return (
    <>
      <BreadCrumbs
        pageName="leftBehindDetails"
        pageTitle="Left-Behind Details"
      />
      {data ? (
        <Row>
          <Col lg="3" md="3" xs="12">
            <Card>
              <CardHeader className="border-bottom">
                <CardTitle tag="h1" className="fw-bolder">
                  <strong>
                    <span className="text-primary">
                      <FontAwesomeIcon icon={faCartFlatbedSuitcase} />
                    </span>{" "}
                    <span className="fw-bolder mb-25">
                      Left-Behind Item Details
                    </span>
                  </strong>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="info-container">
                  <br />
                  <>
                    <Row>
                      <Col xl="12" xs="12">
                        <Row tag="dl" className="mb-0">
                          <Col tag="dt" sm="6" className="fw-bolder mb-1">
                            Flight Number:
                          </Col>
                          <Col tag="dd" sm="6" className="mb-1">
                            <span className="text-primary">
                              <FontAwesomeIcon icon={faPlane} />
                            </span>{" "}
                            {data?.flightNumber}
                          </Col>
                          <Col tag="dt" sm="6" className="fw-bolder mb-1">
                            Flight Date:
                          </Col>
                          <Col tag="dd" sm="6" className="mb-1">
                            {data?.flightDate &&
                              formatDateOnly(new Date(data?.flightDate))}
                          </Col>
                          <Col tag="dt" sm="6" className="fw-bolder mb-1">
                            Destination Station:
                          </Col>
                          <Col tag="dd" sm="6" className="mb-1">
                            {data?.destination}
                          </Col>
                          <Col tag="dt" sm="6" className="fw-bolder mb-1">
                            Fault Station:
                          </Col>
                          <Col tag="dd" sm="6" className="mb-1">
                            {data?.faultStation}
                          </Col>
                          <Col tag="dt" sm="6" className="fw-bolder mb-1">
                            Number of Pieces:
                          </Col>
                          <Col tag="dd" sm="6" className="mb-1">
                            {data?.numberOfPieces}
                          </Col>
                          <Col tag="dt" sm="6" className="fw-bolder mb-1">
                            Remaining Pieces:
                          </Col>
                          <Col tag="dd" sm="6" className="mb-1">
                            <span>
                              <div
                                className={`avatar p-0 m-0 fw-bolder ${
                                  data?.remainingPieces > 0
                                    ? "bg-light-danger"
                                    : "bg-light-success"
                                }`}
                              >
                                <div className="avatar-content">
                                  {data?.remainingPieces}
                                </div>
                              </div>
                            </span>
                          </Col>
                          <Col tag="dt" sm="6" className="fw-bolder mb-1">
                            Reason:
                          </Col>
                          <Col tag="dd" sm="6" className="mb-1">
                            {data?.reason}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <hr />
                      <Col tag="dt" sm="12" className="fw-bolder mb-1">
                        Tags:
                      </Col>
                      <Col tag="dt" sm="12" className="mb-1">
                        {data?.tags ? (
                          <>
                            {" "}
                            {data?.tags.split(";").map((tag, index) => (
                              <Fragment key={index}>
                                <code>{tag.trim()}</code>
                                {index < data?.tags.split(";").length - 1 &&
                                  ", "}
                              </Fragment>
                            ))}
                          </>
                        ) : (
                          "No tags available."
                        )}{" "}
                      </Col>
                    </Row>
                    <Row>
                      <hr />
                      <Col tag="dt" sm="12" className="fw-bolder mb-1">
                        Comments:
                      </Col>
                      <Col tag="dt" sm="12" className="mb-1">
                        {data?.comments
                          ? data?.comments
                          : "No comments available."}{" "}
                      </Col>
                    </Row>
                  </>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="9" md="9" xs="12">
            <ActionToolbar item={data} />
            <Nav tabs>
              <NavItem>
                <NavLink
                  active={active === "1"}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  <FontAwesomeIcon icon={faForward} />
                  Forwarded Actions
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={active === "2"}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  <FontAwesomeIcon icon={faFolderOpen} />
                  Left-Behind Files
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent className="py-50" activeTab={active}>
              <TabPane tabId="1">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Forward</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th className="text-capitalize">Forwarded Pieces</th>
                          <th className="text-capitalize">Flight No</th>
                          <th className="text-capitalize">Flight Date</th>
                          <th className="text-capitalize">
                            Destination Station
                          </th>
                          <th className="text-capitalize">Transit Station</th>
                          <th className="text-capitalize">Tags</th>
                          <th className="text-capitalize">#</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.forwards.length > 0 ? (
                          data?.forwards.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.forwardedPieces}</td>
                              <td>{item.flightNumber}</td>
                              <td>
                                {" "}
                                {item?.flightDate &&
                                  formatDateOnly(new Date(item?.flightDate))}
                              </td>
                              <td>{item.destination}</td>
                              <td>
                                {item.transitStation &&
                                item.transitStation.trim() !== "" ? (
                                  item.transitStation
                                ) : (
                                  <span>-</span>
                                )}
                              </td>
                              <td>
                                {item.tags && item.tags.trim() !== "" ? (
                                  item.tags.split(";").map((tag, index) => (
                                    <Fragment key={index}>
                                      <code>{tag.trim()}</code>
                                      {index <
                                        item.tags.split(";").length - 1 && ", "}
                                    </Fragment>
                                  ))
                                ) : (
                                  <span>-</span>
                                )}
                              </td>
                              <td>
                                <Button.Ripple
                                  className="btn-icon"
                                  color="flat-dark"
                                  id={`detail-${index}`}
                                  onClick={() => toggleModal(item)}
                                >
                                  <Eye className="font-medium-3 text-body mx-1" />
                                </Button.Ripple>
                                <UncontrolledTooltip
                                  target={`detail-${index}`}
                                  placement="top"
                                >
                                  More Forward Details
                                </UncontrolledTooltip>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="12" className="text-center">
                              No items forwarded.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="2">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Files</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <FileDetailView files={data?.files} category="LeftBehind" />
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <p>{error ? error : "No data available."}</p>
          </Col>
        </Row>
      )}
      <ForwardDetailsModal
        modalOpen={modalOpen}
        toggleModal={() => setModalOpen(!modalOpen)}
        selectedForward={selectedForward}
      />
    </>
  );
};

export default LeftBehindDetailsPage;
