import React, { useEffect } from "react";
import {
  FormGroup,
  Button,
  Form,
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Table,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { TextInput } from "@src/components/inputs";
import { useApproveOrderMutation } from "@src/redux/stock/order";
import { useGetOrderTagTypeListQuery } from "@src/redux/stock/tagType";
import { faTag, faCalendar, faPlane } from "@fortawesome/free-solid-svg-icons";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import CalendarInput from "@src/components/inputs/CalendarInput";
import { format } from "date-fns";
import { SelectInput } from "@src/components/inputs";
import LoadingButton from "@src/components/shared/LoadingButton";
import { useParams } from "react-router-dom";
import { useGetOrdertQuery } from "@src/redux/stock/order";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartArrowDown,
  faWeightScale,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";

const ApproveOrder = () => {
  const { id } = useParams();
  const { data: orderData, isFetching } = useGetOrdertQuery(id);
  const [approveOrder, { isLoading }] = useApproveOrderMutation();
  const { data: tagTypes, error } = useGetOrderTagTypeListQuery();
  const navigate = useNavigate();

  const approveOrderSchema = yup.object().shape({
    flightDate: yup
      .date()
      .required()
      .min(
        new Date(new Date().setHours(0, 0, 0, 0)), // Sets the time to midnight (start of the day)
        "Flight date must be today or in the future (UTC)"
      ),
    flightNumber: yup.string().required(),
    tag: yup.string().required(),
    orderTagTypeId: yup.number().required("Tag type is required"),
    orderItems: yup.array().of(
      yup.object().shape({
        approvedQuantity: yup
          .number()
          .required("Approved Quantity is required")
          .min(1, "Approved Quantity must be 0 or greater"),
      })
    ),
  });

  const formMethods = useForm({
    resolver: yupResolver(approveOrderSchema),
    mode: "onSubmit",
    defaultValues: {
      flightDate: "",
      flightNumber: "",
      tag: "",
      orderTagTypeId: "",
      orderItems: orderData?.orderItems.map((item) => ({
        approvedQuantity: item.approvedQuantity || 0,
      })),
    },
  });

  useEffect(() => {
    if (orderData) {
      formMethods.reset({
        flightDate: orderData.flightDate || "",
        flightNumber: orderData.flightNumber || "",
        tag: orderData.tag || "",
        orderTagTypeId: orderData.orderTagTypeId || "",
        orderItems: orderData.orderItems.map((item) => ({
          approvedQuantity: item.quantity || 0,
        })),
      });
    }
  }, [formMethods, orderData]);

  const handleFormSubmit = async (formData) => {
    const approvedOrderItemQuantities = formData.orderItems.map(
      (item, index) => ({
        stockItemId: orderData.orderItems[index].stockItemId,
        approvedQuantity: item.approvedQuantity,
      })
    );

    await approveOrder({
      id: orderData.id,
      flightNumber: formData.flightNumber + "",
      flightDate: format(new Date(formData.flightDate), "yyyy-MM-dd"),
      tag: formData.tag,
      orderTagTypeId: formData.orderTagTypeId,
      approvedOrderItemQuantities,
    })
      .unwrap()
      .then((fulfilled) => {
        SuccessToast("Order approved successfully!");
        navigate(`/baggage-management/stock/orders`);
      })
      .catch((rejected) => {});
  };

  return (
    <>
      <BreadCrumbs pageName="approveOrder" pageTitle="Approve Order" />
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <Card>
            <CardHeader className="border-bottom">
              <CardTitle tag="h1" className="fw-bolder">
                <strong>
                  <span className="text-primary">
                    <FontAwesomeIcon icon={faCartArrowDown} />
                  </span>{" "}
                  <span className="text-primary">Approve Order</span>
                  {"  "}
                  <span className="fw-bolder mb-25">
                    Number ({orderData?.id})
                  </span>{" "}
                </strong>
              </CardTitle>
              <CardTitle tag="h1" className="fw-bolder">
                <strong>
                  <span className="text-primary">
                    <FontAwesomeIcon icon={faPlane} />
                  </span>{" "}
                  <span class="fw-bolder mb-25"> {orderData?.station}</span>{" "}
                  <span class="font-small-2 text-muted">Station</span>
                  <span className="text-muted"> {"       "}</span>
                  &nbsp;&nbsp;-&nbsp;&nbsp;
                  <span className="text-primary">
                    <FontAwesomeIcon icon={faWeightScale} />
                  </span>{" "}
                  <span class="fw-bolder mb-25">
                    {" "}
                    {Number.isInteger(orderData?.totalWeight)
                      ? orderData?.totalWeight
                      : orderData?.totalWeight.toFixed(2)}
                  </span>{" "}
                  <span class="font-small-2 text-muted">
                    Total Weight{" "}
                    <span class="font-small-2 text-muted">(kg)</span>
                  </span>
                </strong>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <div className="info-container">
                <br />
                <Row>
                  <Col md={3}>
                    <div className="mb-1">
                      <TextInput
                        twoLines={true}
                        label="Flight Number"
                        name="flightNumber"
                        icon={faPlane}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-1">
                      <CalendarInput
                        twoLines={true}
                        label="Flight Date"
                        name="flightDate"
                        icon={faCalendar}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <SelectInput
                        twoLines={true}
                        label="Tag Type"
                        name="orderTagTypeId"
                        icon={faCalendar}
                        options={
                          tagTypes?.map((type) => ({
                            value: type.id,
                            label: type.name,
                          })) || []
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <TextInput
                        twoLines={true}
                        label="Tag"
                        name="tag"
                        icon={faTag}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <h4 className="text-warning mb-1">Order Items</h4>
                    <Table responsive bordered>
                      <thead>
                        <tr>
                          <th>Item Name</th>
                          <th>Quantity Type</th>
                          <th>Quantity</th>
                          <th>
                            {" "}
                            Total Weight{" "}
                            <span class="font-small-2 text-muted">(kg)</span>
                          </th>
                          <th>
                            {" "}
                            Available Pieces{" "}
                            <span class="font-small-2 text-muted">
                              (in stock)
                            </span>
                          </th>
                          <th>Pieces Per Box</th>
                          <th>Approved Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderData?.orderItems?.map((item, index) => (
                          <tr key={index}>
                            <td className="text-center">
                              {item.stockItemName}
                            </td>
                            <td className="text-center">
                              {item.quantityTypeName}
                            </td>
                            <td className="text-center">{item.quantity}</td>
                            <td className="text-center">{item.totalWeight}</td>
                            <td className="text-center">
                              {item.availablePieces}
                            </td>
                            <td className="text-center">{item.piecesPerBox}</td>
                            <td className="text-center">
                              <TextInput
                                twoLines
                                label=""
                                name={`orderItems[${index}].approvedQuantity`}
                                defaultValue={item.quantity}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </CardBody>
            <CardFooter>
              <LoadingButton loading={isLoading} type="submit" color="primary">
                Save
              </LoadingButton>
              <Button
                type="button"
                color="warning"
                className="mx-1"
                onClick={() => {
                  navigate(`/stock/orders`);
                }}
              >
                Cancel
              </Button>
            </CardFooter>
          </Card>
        </Form>
      </FormProvider>
    </>
  );
};

export default ApproveOrder;
