import React, { Fragment, useEffect, useState, memo } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  Alert,
  Table,
} from "reactstrap";
import {
  useUpdateOrderMutation,
  useGetOrdertQuery,
} from "@src/redux/stock/order";
import { useGetQuantityTypeListQuery } from "@src/redux/stock/quantityType";
import { useGetItemListQuery } from "@src/redux/stock/item";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "@src/components/shared/Loader";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { TextInput, ReadonlyInput } from "@src/components/inputs";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import { Plus, Edit, AlertCircle } from "react-feather";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import AddItemModal from "./AddItemModal";

const repeaterItemSchema = yup.object().shape({
  stockItemId: yup.string().required("Stock item is required"),
  quantityTypeId: yup.string().required("Quantity type is required"),
  quantity: yup.number().required("Quantity is required").positive().integer(),
});

const orderSchema = yup.object().shape({
  comments: yup.string().nullable(),
  orderItems: yup
    .array()
    .of(repeaterItemSchema)
    .min(1, "At least one item is required")
    .test("no-duplicate-items", "Duplicate items are not allowed", (value) => {
      const stockItemIds = value.map((item) => item.stockItemId);
      return new Set(stockItemIds).size == stockItemIds.length;
    }),
});

const UpdateOrder = () => {
  const { id } = useParams();
  const { data, isFetching, error } = useGetOrdertQuery(id);
  const navigate = useNavigate();
  const [updateOrderMutation] = useUpdateOrderMutation();
  const { data: quantityTypeList } = useGetQuantityTypeListQuery();
  const { data: itemlist } = useGetItemListQuery();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const formMethods = useForm({
    resolver: yupResolver(orderSchema),
    mode: "onSubmit",
    defaultValues: {
      comments: "",
      orderItems: [{ stockItemId: "", quantityTypeId: "", quantity: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: formMethods.control,
    name: "orderItems",
  });

  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    if (data) {
      formMethods.reset({
        comments: data.comments,
        orderItems: data.orderItems.map((item) => ({
          ...item,
          stockItemId: item.stockItemId,
          quantityTypeId: item.quantityTypeId,
          quantity: item.quantity,
          orderItemId: item.id,
        })),
      });
    }
  }, [data, formMethods]);

  const handleFormSubmit = async (formData) => {
    const stockItemIds = formData.orderItems.map((item) => item.stockItemId);
    const hasDuplicates = new Set(stockItemIds).size !== stockItemIds.length;

    if (hasDuplicates) {
      setError("orderItems", {
        type: "manual",
        message: "Duplicate items are not allowed",
      });
      return;
    }

    const mappedData = {
      id,
      comments: formData.comments || "",
      orderItems: formData.orderItems.map((item) => ({
        orderItemId: item.id ? parseInt(item.id, 10) : null,
        stockItemId: parseInt(item.stockItemId, 10),
        quantity: parseInt(item.quantity, 10),
        quantityTypeId: parseInt(item.quantityTypeId, 10),
      })),
    };

    await updateOrderMutation(mappedData)
      .unwrap()
      .then((fulfilled) => {
        SuccessToast("Order Updated Successfully!");
        navigate(`/baggage-management/stock/orders/${id}`);
      })
      .catch((rejected) => {});
  };

  const handleEditItem = (item, index) => {
    setCurrentItem({ ...item, index });
    setIsEditing(true);
    toggleModal();
  };

  const addItem = () => {
    setCurrentItem(null);
    setIsEditing(false);
    toggleModal();
  };

  const handleModalSave = (newItem) => {
    const orderItems = formMethods.getValues("orderItems");
    if (isEditing) {
      const updatedItems = orderItems.map((item, idx) =>
        idx == currentItem.index ? newItem : item
      );
      formMethods.setValue("orderItems", updatedItems);
    } else {
      formMethods.setValue("orderItems", [...orderItems, newItem]);
    }
    toggleModal();
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const orderItems = formMethods.watch("orderItems");

  if (isFetching) {
    return <Loader />;
  }

  if (error) {
    return <Alert color="danger">Error loading order data.</Alert>;
  }

  return (
    <Fragment>
      <BreadCrumbs pageName="updateOrder" pageTitle="Update Order" />
      <Card>
        <FormProvider {...formMethods}>
          <Form onSubmit={handleSubmit(handleFormSubmit)}>
            <CardHeader className="border-bottom">
              <CardTitle tag="h4">Update Order</CardTitle>
              <div>
                <Button type="submit" color="primary">
                  Update
                </Button>
                <Button
                  type="button"
                  color="warning"
                  className="mx-1"
                  onClick={() => navigate(`/baggage-management/stock/orders`)}
                >
                  Cancel
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <Row>
                <Col lg="6" md="6" className="mb-1">
                  <ReadonlyInput label="Station" defaultValue={data?.station} />
                </Col>
              </Row>
              <Row>
                <Col lg="12" md="12" className="mb-1">
                  <hr />
                  <CardTitle
                    tag="h4"
                    className="mb-0 fw-bolder d-flex justify-content-between"
                  >
                    <span>
                      Order Items
                      <p className="text-muted m-0 font-small-2">
                        Order must contain at least one item
                      </p>
                    </span>
                    <Button
                      outline
                      className="btn-icon"
                      color="warning"
                      onClick={addItem}
                    >
                      <Plus size={14} />
                      <span className="align-middle ms-25">Add Item</span>
                    </Button>
                  </CardTitle>

                  {orderItems.length > 0 ? (
                    <Table responsive bordered className="mt-2">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Stock Item</th>
                          <th>Quantity Type</th>
                          <th>Quantity</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fields.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {itemlist?.find(
                                (itm) => itm.id == item.stockItemId
                              )?.name || "Unknown Item"}
                            </td>
                            <td>
                              {quantityTypeList?.find(
                                (qt) => qt.id == item.quantityTypeId
                              )?.name || "Unknown Type"}
                            </td>
                            <td>{item.quantity}</td>
                            <td>
                              <Button
                                outline
                                color="primary"
                                className="me-1"
                                onClick={() => handleEditItem(item, index)}
                              >
                                <Edit size={14} />
                              </Button>
                              <Button
                                outline
                                color="danger"
                                onClick={() => remove(index)}
                              >
                                Remove
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <p className="text-muted text-center">
                      No order items yet, select one at least.
                    </p>
                  )}

                  {errors.orderItems && (
                    <Alert color="danger">
                      <div className="alert-body">
                        <AlertCircle size={14} />
                        <span className="align-middle ms-50">
                          {errors.orderItems.message}
                        </span>
                      </div>
                    </Alert>
                  )}
                  <hr />
                </Col>
                <Col lg="12" md="12" className="mb-1">
                  <TextInput
                    twoLines
                    label="Comments"
                    name="comments"
                    type="textarea"
                    rows="3"
                    icon={faMessage}
                  />
                </Col>
              </Row>
            </CardBody>
          </Form>
        </FormProvider>
      </Card>

      <AddItemModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        onSave={handleModalSave}
        item={currentItem}
        isEditing={isEditing}
        itemlist={itemlist}
        quantityTypeList={quantityTypeList}
      />
    </Fragment>
  );
};

export default memo(UpdateOrder);
