import React, { Fragment } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Button,
} from "reactstrap";
import { ReadonlyInput } from "@src/components/inputs";
import CopyToClipboard from "@src/components/shared/CopyToClipboard";
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";

const FoundItemDetailsModal = ({ modalOpen, toggleModal, selectedItem }) => {
  return (
    <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
      <ModalHeader toggle={toggleModal}>Found Item Details</ModalHeader>
      <ModalBody>
        {selectedItem ? (
          <>
            <Row>
              <Col md={6}>
                <Row>
                  <Col tag="dt" sm="5" className="fw-bolder mb-1">
                    item Type:
                  </Col>
                  <Col tag="dd" sm="7" className="mb-1">
                    {selectedItem.itemTypeName}
                  </Col>
                  <Col tag="dt" sm="5" className="fw-bolder mb-1">
                    isValuable:
                  </Col>
                  <Col tag="dd" sm="7" className="mb-1">
                    {selectedItem.isValuable == false ? "No" : "Yes"}
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col tag="dt" sm="5" className="fw-bolder mb-1">
                    Amount:
                  </Col>
                  <Col tag="dd" sm="7" className="mb-1">
                    {selectedItem.amount || "-"}
                  </Col>
                  <Col tag="dt" sm="5" className="fw-bolder mb-1">
                    Kind:
                  </Col>
                  <Col tag="dd" sm="7" className="mb-1">
                    {selectedItem.kind || "-"}
                  </Col>
                </Row>
              </Col>
              <hr></hr>
              <Col tag="dd" sm="12" className="mb-1">
                <div className="mb-1">
                  {selectedItem.description &&
                  selectedItem.description.trim() !== "" ? (
                    <>
                      <ReadonlyInput
                        label="Description"
                        name="description"
                        icon={faCommentAlt}
                        type="textarea"
                        rows="5"
                        defaultValue={selectedItem.description}
                      />
                      <CopyToClipboard
                        value={selectedItem.description}
                        defaultMsg="Click to copy"
                        successMsg="Copied to clipboard!"
                      />
                    </>
                  ) : (
                    <span>No description available</span>
                  )}
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <p>No details available.</p>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="warning" onClick={toggleModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FoundItemDetailsModal;
