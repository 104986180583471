import { SuccessToast } from "@src/components/shared/SuccessToast";
import ConfirmSweetAlert from "@src/components/shared/ConfirmSweetAlert";
import { File } from "react-feather";
import { saveAs } from "file-saver";
import Loader from "@src/components/shared/Loader";
import LoadingButton from "@src/components/shared/LoadingButton";

const Export = ({
  filters,
  exportFunction,
  isLoading,
  exportedEntity,
  warningMessage,
}) => {
  const capitalizedEntity =
    exportedEntity.charAt(0).toUpperCase() + exportedEntity.slice(1);
  warningMessage = warningMessage
    ? warningMessage
    : `If no filtration was applied, all ${exportedEntity} will be exported.`;

  const handleExport = async (filters) => {
    ConfirmSweetAlert({
      title: `Are you sure , you need to export ${exportedEntity}?`,
      text: warningMessage,
      icon: "question",
      confirmButtonText: "Export",
      showCancelButton: true,
      cancelButtonClasses: "btn btn-warning ms-1",
      cancelButtonText: "Cancel",
      onConfirm: async () => {
        let fetchFilters = { ...filters };
        fetchFilters.advancedFilter ??= {};
        const response = await exportFunction(fetchFilters)
          .unwrap()
          .then((fulfilled) => {
            const blob = new Blob([response], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const fileName = `exported_${exportedEntity}_${new Date()
              .toISOString()
              .replace(/:/g, "-")}.xlsx`;

            saveAs(blob, fileName);
            SuccessToast(`${capitalizedEntity} exported Successfully!`);
          })
          .catch((rejected) => {});
      },
    });
  };

  return (
    <>
      {isLoading && <Loader />}
      <LoadingButton
        loading={isLoading}
        color="secondary"
        className="me-1"
        onClick={() => handleExport(filters)}
      >
        <File size={18} />
        &nbsp; Export
      </LoadingButton>
    </>
  );
};

export default Export;
