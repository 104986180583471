import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Button,
  Form,
  Col,
  Row,
  Label,
  Input,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import * as yup from "yup";
import { TextInput, CalendarInput } from "@src/components/inputs";
import {
  useDeliverLzBaggageItemToPassengerMutation,
  useDeliverLzBaggageItemToCharityMutation,
} from "@src/redux/lz-baggage/list";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import LoadingButton from "@src/components/shared/LoadingButton";
import {
  faCalendar,
  faPlane,
  faTag,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";

const DeliverModal = ({ isVisible, toggle, baggageData }) => {
  const [deliveryType, setDeliveryType] = useState("passenger");

  const [deliverToPassenger, { isLoading: isLoadingPassenger }] =
    useDeliverLzBaggageItemToPassengerMutation();
  const [deliverToCharity, { isLoading: isLoadingCharity }] =
    useDeliverLzBaggageItemToCharityMutation();

  const isLoading = isLoadingPassenger || isLoadingCharity;

  const deliverSchema = yup.object().shape({
    deliveryType: yup.string().required(),
    passengerName: yup.string().when("deliveryType", {
      is: "passenger",
      then: yup.string().required("Passenger Name is required"),
    }),
    passengerIdNum: yup.string().when("deliveryType", {
      is: "passenger",
      then: yup.string().required("Passenger ID is required"),
    }),
    deliveryDate: yup.date().required("Delivery Date is required"),
    charityName: yup.string().when("deliveryType", {
      is: "charity",
      then: yup.string().required("Charity Name is required"),
    }),
    charityContact: yup.string(),
    comments: yup.string(),
  });

  const formMethods = useForm({
    resolver: yupResolver(deliverSchema),
    mode: "onSubmit",
    defaultValues: {
      deliveryType: "passenger",
      passengerName: "",
      passengerIdNum: "",
      deliveryDate: "",
      charityName: "",
      charityContact: "",
      comments: "",
    },
  });

  const deliveryTypeWatch = useWatch({
    control: formMethods.control,
    name: "deliveryType",
  });

  useEffect(() => {
    if (isVisible) {
      formMethods.reset();
      setDeliveryType("passenger");
    }
  }, [isVisible, formMethods]);

  const handleFormSubmit = async (formData) => {
    try {
      var deliveryDate = format(new Date(formData.deliveryDate), "yyyy-MM-dd");
      if (formData.deliveryType === "passenger") {
        await deliverToPassenger({
          id: baggageData.id,
          PassengerName: formData.passengerName,
          PassengerIdNum: formData.passengerIdNum,
          DeliveryDate: deliveryDate,
        }).unwrap();
      } else {
        await deliverToCharity({
          id: baggageData.id,
          CharityName: formData.charityName,
          CharityContact: formData.charityContact,
          Comments: formData.comments,
          DeliveredDate: deliveryDate,
        }).unwrap();
      }
      SuccessToast("LZ Baggage delivered successfully!");
      toggle();
    } catch (error) {
      console.error("Delivery failed", error);
    }
  };

  return (
    <Modal isOpen={isVisible} toggle={toggle} size="lg">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>Deliver LZ Baggage</ModalHeader>
          <ModalBody>
            <Row>
              <Col md={12}>
                <div className="mb-2">
                  <FormGroup check inline>
                    <Input
                      type="radio"
                      name="deliveryType"
                      value="passenger"
                      checked={deliveryTypeWatch === "passenger"}
                      onChange={(e) =>
                        formMethods.setValue("deliveryType", e.target.value)
                      }
                      id="deliveryTypePassenger"
                    />
                    <Label check htmlFor="deliveryTypePassenger">
                      Delivery To Passenger
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Input
                      type="radio"
                      name="deliveryType"
                      value="charity"
                      checked={deliveryTypeWatch === "charity"}
                      onChange={(e) =>
                        formMethods.setValue("deliveryType", e.target.value)
                      }
                      id="deliveryTypeCharity"
                    />
                    <Label check htmlFor="deliveryTypeCharity">
                      Delivery To Charity
                    </Label>
                  </FormGroup>
                </div>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-2">
                  <CalendarInput
                    twoLines={true}
                    label="Delivery Date"
                    name="deliveryDate"
                    icon={faCalendar}
                  />
                </div>
              </Col>
            </Row>
            {deliveryTypeWatch === "passenger" && (
              <Row>
                <Col md={6}>
                  <div className="mb-2">
                    <TextInput
                      twoLines
                      label="Passenger Name"
                      name="passengerName"
                      icon={faTag}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-2">
                    <TextInput
                      twoLines
                      label="Passenger ID"
                      name="passengerIdNum"
                      icon={faPlane}
                    />
                  </div>
                </Col>
              </Row>
            )}
            {deliveryTypeWatch === "charity" && (
              <>
                <Row>
                  <Col md={6}>
                    <div className="mb-2">
                      <TextInput
                        twoLines
                        label="Charity Name"
                        name="charityName"
                        icon={faTag}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-2">
                      <TextInput
                        twoLines
                        label="Charity Contact"
                        name="charityContact"
                        icon={faPhone}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <TextInput
                      label="Comments"
                      name="comments"
                      type="textarea"
                      rows="4"
                    />
                  </Col>
                </Row>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <LoadingButton loading={isLoading} type="submit" color="primary">
              Save
            </LoadingButton>
            <Button type="button" color="warning" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default DeliverModal;
