import Table from "@src/components/DataTable/Table";
import { Card, CardBody } from "reactstrap";
import { useGetQuantityTypesQuery } from "@src/redux/stock/quantityType";
import quantityTypeColumns from "./columns";
import { useState } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import QuantityTypeModal from "./quantityTypeModal";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";

const QuantityType = () => {
  const [visible, setVisible] = useState(false);
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: null,
    keyword: "",
  });

  const initialDtFilters = {
    name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    type: {
      value: null,
      matchMode: FilterMatchMode.EQUALS,
    },
    description: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  const [dtFilters, setDtFilters] = useState(initialDtFilters);
  const { data, isLoading } = useGetQuantityTypesQuery(filters);

  const clearFilters = () => {
    setDtFilters(initialDtFilters);
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: null,
      keyword: "",
    });
  };

  return (
    <>
      <BreadCrumbs pageName="quantityTypeList" pageTitle="Quantity Type List" />
      <Card>
        <CardBody>
          <Table
            metaData={{
              ...data?.metadata,
              loading: isLoading,
            }}
            columns={quantityTypeColumns}
            values={data?.items || []}
            dataKey="id"
            emptyMessage="No Quantity Types Found..."
            filters={dtFilters}
            setTableState={setFilters}
            clearFilter={clearFilters}
            displayAdd={true}
            handleAdd={() => setVisible(true)}
            tableState={filters}
          />
        </CardBody>
      </Card>
      <QuantityTypeModal visible={visible} toggle={() => setVisible(false)} />
    </>
  );
};

export default QuantityType;
