import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Col,
  Row,
} from "reactstrap";
import { useAddPossibleOwnerActionMutation } from "@src/redux/found-item/possible-owners";
import { TextInput } from "@src/components/inputs";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import LoadingButton from "@src/components/shared/LoadingButton";

const ConnectOwnerModal = ({ isOpen, toggle, possibleOwner, foundItemId }) => {
  const [addAction, { isLoading }] = useAddPossibleOwnerActionMutation();

  const connectOwnerSchema = yup.object().shape({
    comments: yup.string().nullable(),
  });

  const formMethods = useForm({
    resolver: yupResolver(connectOwnerSchema),
    mode: "onSubmit",
    defaultValues: {
      comments: "",
    },
  });

  useEffect(() => {
    if (isOpen) {
      formMethods.reset({
        comments: "",
      });
    }
  }, [isOpen, formMethods]);

  const handleFormSubmit = async (formData) => {
    await addAction({
      possibleOwnerId: possibleOwner.id,
      foundItemId,
      action: {
        actionType: "contactByPhone",
        comments: formData.comments,
      },
    })
      .unwrap()
      .then((fulfilled) => {
        SuccessToast("Action posted successfully!");
        toggle(false);
      })
      .catch((rejected) => {});
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>
            Post New Action for {possibleOwner?.passengerName}
          </ModalHeader>
          <ModalBody>
            <Form>
              <Row>
                <Col md={12}>
                  <div className="mb-1">
                    <TextInput
                      twoLines={true}
                      label="Comments"
                      name="comments"
                      icon={faTag}
                      type="textarea"
                      rows="5"
                    />
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <LoadingButton loading={isLoading} type="submit" color="primary">
              Post Action
            </LoadingButton>
            <Button type="button" color="warning" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default ConnectOwnerModal;
