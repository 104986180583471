import { SuccessToast } from "@src/components/shared/SuccessToast";
import ConfirmSweetAlert from "@src/components/shared/ConfirmSweetAlert";
import { saveAs } from "file-saver";
import Loader from "@src/components/shared/Loader";
import { usePrintFoundItemMutation } from "@src/redux/found-item/list";
import { Button, UncontrolledTooltip } from "reactstrap";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PrintFoundItem = ({ isIcon = false, foundItemid }) => {
  const [printFoundItem, { isLoading }] = usePrintFoundItemMutation();

  const handlePrint = async (foundItemId) => {
    ConfirmSweetAlert({
      title: `Are you sure you want to print this found item info?`,
      icon: "question",
      confirmButtonText: "Print",
      showCancelButton: true,
      cancelButtonClasses: "btn btn-warning ms-1",
      cancelButtonText: "Cancel",
      onConfirm: async () => {
        try {
          const response = await printFoundItem({ foundItemId }).unwrap();
          const blob = new Blob([response], { type: "application/pdf" });
          const fileName = `exported_founditem_number(${foundItemId})_${new Date()
            .toISOString()
            .replace(/:/g, "-")}.pdf`;

          saveAs(blob, fileName);
          SuccessToast(`Found Item exported successfully as PDF!`);
        } catch (error) {
          console.error("Error while exporting PDF:", error);
        }
      },
    });
  };

  if (isIcon)
    return (
      <>
        {isLoading && <Loader />}
        <Button
          style={{ padding: ".515rem .536rem" }}
          id="print"
          className="btn-icon rounded-circle ms-1"
          outline
          color="info"
          onClick={() => {
            handlePrint(foundItemid);
          }}
        >
          <span className="text-uppercase">
            <FontAwesomeIcon icon={faPrint} className="font-medium-3" />
          </span>
        </Button>
        <UncontrolledTooltip target="print" placement="top">
          Print
        </UncontrolledTooltip>
      </>
    );

  return (
    <>
      {isLoading && <Loader />}
      <Button
        outline
        type="button"
        color="secondary"
        onClick={() => handlePrint(foundItemid)}
      >
        <FontAwesomeIcon icon={faPrint} className="font-medium-1" />
        &nbsp; Print
      </Button>
    </>
  );
};

export default PrintFoundItem;
