import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TextInput, SelectInput, ReadonlyInput } from "@src/components/inputs";
import { useForm, FormProvider } from "react-hook-form";

const itemSchema = yup.object().shape({
  stockItemId: yup.string().required("Stock item is required"),
  quantityTypeId: yup.string().required("Quantity type is required"),
  quantity: yup.number().required("Quantity is required").positive().integer(),
});

const AddItemModal = ({
  isOpen,
  toggleModal,
  onSave,
  item,
  isEditing,
  itemlist,
  quantityTypeList,
}) => {
  const formMethods = useForm({
    resolver: yupResolver(itemSchema),
    mode: "onSubmit",
    defaultValues: {
      stockItemId: "",
      quantityTypeId: "",
      quantity: "",
    },
  });

  useEffect(() => {
    if (isOpen) {
      if (isEditing && item) {
        formMethods.reset({
          stockItemId: parseInt(item.stockItemId, 10),
          quantityTypeId: parseInt(item.quantityTypeId, 10),
          quantity: item.quantity,
        });
      } else {
        formMethods.reset({
          stockItemId: "",
          quantityTypeId: "",
          quantity: "",
        });
      }
    }
  }, [isOpen, item, isEditing, formMethods]);

  const onSubmit = (data) => {
    onSave(data);
    toggleModal();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <ModalHeader toggle={toggleModal}>
            {isEditing ? "Edit Item" : "Add Item"}
          </ModalHeader>
          <ModalBody>
            <div className="mb-2">
              {isEditing && item?.orderItemId ? (
                <ReadonlyInput
                  twoLines
                  label="Stock Item"
                  name="stockItemName"
                  defaultValue={
                    itemlist?.find((itm) => itm.id === item.stockItemId)
                      ?.name || "Unknown Item"
                  }
                />
              ) : (
                <SelectInput
                  twoLines
                  label="Stock Item"
                  name="stockItemId"
                  options={
                    itemlist?.map((item) => ({
                      value: item.id,
                      label: item.name,
                    })) || []
                  }
                />
              )}
            </div>
            <div className="mb-2">
              <SelectInput
                twoLines
                label="Quantity Type"
                name="quantityTypeId"
                options={
                  quantityTypeList?.map((item) => ({
                    value: item.id,
                    label: item.name,
                  })) || []
                }
              />
            </div>
            <div className="mb-2">
              <TextInput twoLines label="Quantity" name="quantity" />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary">
              Save
            </Button>
            <Button type="button" color="warning" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default AddItemModal;
