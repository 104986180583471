import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Row,
  Col,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TextInput, SelectInput } from "@src/components/inputs";
import { useForm, FormProvider } from "react-hook-form";

const AddFoundItemModal = ({
  isOpen,
  toggleModal,
  onSave,
  item,
  isEditing,
  itemTypeList,
}) => {
  const [isValuable, setIsValuable] = useState(false);
  const [isOtherSelected, setIsOtherSelected] = useState(false);

  const itemSchema = yup.object().shape({
    itemTypeId: yup.number().required(),
    name: yup
      .string()
      .nullable()
      .when("itemTypeId", {
        is: (value) => {
          const selectedItem = itemTypeList?.find(
            (item) => item.id === parseInt(value, 10)
          );
          return selectedItem?.isOther;
        },
        then: yup
          .string()
          .required("Item name is required when 'Other' is selected"),
      }),
    description: yup.string().nullable(),
    kind: yup.string().nullable(),
    amount: yup.number().nullable(),
  });

  const formMethods = useForm({
    resolver: yupResolver(itemSchema),
    mode: "onSubmit",
    defaultValues: {
      itemTypeId: "",
      name: "",
      description: "",
      kind: "",
      amount: 0,
    },
  });

  const {
    watch,
    reset,
    setValue,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
  } = formMethods;

  const selectedItemTypeId = watch("itemTypeId");

  useEffect(() => {
    const selectedItem = itemTypeList?.find(
      (item) => item.id === parseInt(selectedItemTypeId, 10)
    );

    setIsValuable(selectedItem?.isValuable);
    if (!selectedItem?.isValuable) {
      setValue("kind", "");
      setValue("amount", 0);
      clearErrors(["kind", "amount"]);
    }

    if (selectedItem?.isOther) {
      setIsOtherSelected(true);
    } else {
      setIsOtherSelected(false);
      setValue("name", null);
    }
  }, [selectedItemTypeId, itemTypeList, setValue, clearErrors]);

  useEffect(() => {
    if (isOpen) {
      if (isEditing && item) {
        formMethods.reset({
          itemTypeId: parseInt(item.itemTypeId, 10) || "0",
          name: item.name || "",
          description: item.description,
          kind: item.kind,
          amount: parseInt(item.amount, 10),
        });
      } else {
        formMethods.reset({
          itemTypeId: "",
          name: "",
          description: "",
          kind: "",
          amount: 0,
        });
      }
    }
  }, [isOpen, item, isEditing, formMethods]);

  const onSubmit = (data) => {
    if (isValuable) {
      if (!data.kind) {
        setError("kind", { type: "manual", message: "Kind is required" });
        return;
      }
      if (!data.amount || data.amount <= 0) {
        setError("amount", {
          type: "manual",
          message: "Amount is required",
        });
        return;
      }
    } else {
      data.amount = null;
      data.kind = null;
    }

    onSave(data);
    toggleModal();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <ModalHeader toggle={toggleModal}>
            {isEditing ? "Edit Item" : "Add Item"}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col lg="12" md="12" className="mb-1">
                <SelectInput
                  twoLines
                  label="Item Type"
                  name="itemTypeId"
                  options={[
                    ...(itemTypeList?.map((item) => ({
                      value: item.id,
                      label: `${item.name}${
                        item.isValuable ? " (valuable)" : ""
                      }`,
                    })) || []),
                  ]}
                />
              </Col>
              {isOtherSelected && (
                <Col lg="12" md="12" className="mb-1">
                  <TextInput twoLines label="Item Name" name="name" />
                </Col>
              )}
              {isValuable && (
                <>
                  <Col lg="12" md="12" className="mb-1">
                    <TextInput twoLines label="Kind" name="kind" />
                  </Col>
                  <Col lg="12" md="12" className="mb-1">
                    <TextInput twoLines label="Amount" name="amount" />
                  </Col>
                </>
              )}
              <Col lg="12" md="12" className="mb-1">
                <TextInput
                  twoLines={true}
                  label="Description"
                  name="description"
                  type="textarea"
                  rows="3"
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary">
              Save
            </Button>
            <Button type="button" color="warning" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default AddFoundItemModal;
