import { mainApi } from "../mainApi";

export const logsApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getLzBaggageLogs: builder.query({
      query: (filters) => ({
        url: `/LzBaggage/logs/search`,
        method: "POST",
        body: filters,
      }),
      transformResponse: (response, meta, arg) => {
        const { data, ...metadata } = response;
        return { items: data, metadata };
      },
      providesTags: (result) => [{ type: "Logs", id: "Search" }],
    }),
    exportLzBaggageLogs: builder.mutation({
      query: (filters) => ({
        url: `/LzBaggage/logs/export`,
        method: "POST",
        body: filters,
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetLzBaggageLogsQuery, useExportLzBaggageLogsMutation } =
  logsApi;
