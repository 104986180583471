import React, { Fragment, useState, useEffect, memo } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  Alert,
  Table,
} from "reactstrap";
import { useAddOrderMutation } from "@src/redux/stock/order";
import { useGetStationListQuery } from "@src/redux/station/station";
import { useGetQuantityTypeListQuery } from "@src/redux/stock/quantityType";
import { useGetItemListQuery } from "@src/redux/stock/item";
import { useNavigate } from "react-router-dom";
import Loader from "@src/components/shared/Loader";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { TextInput, SelectInput } from "@src/components/inputs";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import { Plus, AlertCircle, Edit, X } from "react-feather";
import LoadingButton from "@src/components/shared/LoadingButton";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import AddItemModal from "./AddItemModal";

const repeaterItemSchema = yup.object().shape({
  stockItemId: yup.string().required("Stock item is required"),
  quantityTypeId: yup.string().required("Quantity type is required"),
  quantity: yup.number().required("Quantity is required").positive().integer(),
});

const orderSchema = yup.object().shape({
  station: yup.string().required(),
  comments: yup.string().nullable(),
  orderItems: yup
    .array()
    .of(repeaterItemSchema)
    .min(1, "At least one item is required")
    .test(
      "no-duplicate-stockItemId",
      "Duplicate stock items are not allowed",
      (orderItems) => {
        const stockItemIds = orderItems.map((item) => item.stockItemId);
        return new Set(stockItemIds).size == stockItemIds.length;
      }
    ),
});

const AddOrder = () => {
  const navigate = useNavigate();
  const [addOrderMutation, { isLoading }] = useAddOrderMutation();
  const { data: stationList, isFetching } = useGetStationListQuery();
  const { data: quantityTypeList } = useGetQuantityTypeListQuery();
  const { data: itemlist } = useGetItemListQuery();

  const formMethods = useForm({
    resolver: yupResolver(orderSchema),
    mode: "onSubmit",
    defaultValues: {
      station: "",
      comments: "",
      orderItems: [],
    },
  });

  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = formMethods;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    formMethods.reset({
      station: "",
      comments: "",
      orderItems: [],
    });
  }, [formMethods]);

  const handleFormSubmit = async (formData) => {
    await orderSchema.validate(formData, { abortEarly: false });
    await addOrderMutation(formData)
      .unwrap()
      .then((fulfilled) => {
        SuccessToast("Order Added Successfully!");
        navigate(`/baggage-management/stock/orders`);
      })
      .catch((rejected) => {});
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setCurrentItem(null);
    setIsEditing(false);
  };

  const handleModalSave = (newItem) => {
    const orderItems = formMethods.getValues("orderItems");
    if (isEditing) {
      const updatedItems = orderItems.map((item, idx) =>
        idx == currentItem.index ? newItem : item
      );
      formMethods.setValue("orderItems", updatedItems);
    } else {
      formMethods.setValue("orderItems", [...orderItems, newItem]);
    }
    toggleModal();
  };

  const handleEditItem = (item, index) => {
    toggleModal();
    setIsEditing(true);
    setCurrentItem({ ...item, index });
  };

  const handleRemoveItem = (index) => {
    const orderItems = formMethods.getValues("orderItems");
    const updatedItems = orderItems.filter((_, idx) => idx !== index);
    formMethods.setValue("orderItems", updatedItems);
  };

  const getStockItemName = (stockItemId) => {
    const item = itemlist?.find((itm) => itm.id == stockItemId);
    return item ? item.name : "";
  };

  const getQuantityTypeName = (quantityTypeId) => {
    const quantityType = quantityTypeList?.find(
      (qt) => qt.id == quantityTypeId
    );
    return quantityType ? quantityType.name : "";
  };

  const orderItems = formMethods.watch("orderItems");

  if (isFetching) {
    return <Loader />;
  }

  return (
    <Fragment>
      <BreadCrumbs pageName="addOrder" pageTitle="Add Order" />
      <Card>
        <FormProvider {...formMethods}>
          <Form onSubmit={handleSubmit(handleFormSubmit)}>
            <CardHeader className="border-bottom">
              <CardTitle tag="h4">Add Order</CardTitle>
              <div>
                <LoadingButton
                  loading={isLoading}
                  type="submit"
                  color="primary"
                >
                  Save
                </LoadingButton>
                <Button
                  type="button"
                  color="warning"
                  className="mx-1"
                  onClick={() => {
                    navigate(`/baggage-management/stock/orders`);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <div className="info-container">
                <Row>
                  <Col lg="6" md="6" className="mb-1">
                    <SelectInput
                      twoLines
                      label="Station"
                      name="station"
                      options={
                        stationList?.map((type) => ({
                          value: type.id,
                          label: type.id,
                        })) || []
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" md="12" className="mb-1">
                    <hr />
                    <CardTitle
                      tag="h4"
                      className="mb-0 fw-bolder"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>
                        Order Items
                        <p className="text-muted m-0 font-small-2">
                          Order must contain one item at least
                        </p>
                      </span>
                      <Button
                        outline
                        className="btn-icon"
                        color="warning"
                        onClick={toggleModal}
                      >
                        <Plus size={14} />
                        <span className="align-middle ms-25">Add Item</span>
                      </Button>
                    </CardTitle>

                    {orderItems.length > 0 ? (
                      <Table responsive bordered className="mt-2">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Stock Item</th>
                            <th>Quantity Type</th>
                            <th>Quantity</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderItems.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{getStockItemName(item.stockItemId)}</td>
                              <td>
                                {getQuantityTypeName(item.quantityTypeId)}
                              </td>
                              <td>{item.quantity}</td>
                              <td>
                                <Button
                                  outline
                                  color="primary"
                                  className="me-1"
                                  onClick={() => handleEditItem(item, index)}
                                >
                                  <Edit size={14} />
                                </Button>
                                <Button
                                  outline
                                  color="danger"
                                  onClick={() => handleRemoveItem(index)}
                                >
                                  <X size={14} />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <Alert color="dark">
                        <div className="alert-body mt-1">
                          <AlertCircle size={14} />
                          <span className="align-middle ms-50">
                            No order items yet, select one at least.
                          </span>
                        </div>
                      </Alert>
                    )}

                    {errors.orderItems && (
                      <Alert color="danger">
                        <div className="alert-body">
                          <AlertCircle size={14} />
                          <span className="align-middle ms-50">
                            {errors.orderItems.message}
                          </span>
                        </div>
                      </Alert>
                    )}
                    <hr />
                  </Col>
                  <Col lg="12" md="12" className="mb-1">
                    <TextInput
                      twoLines
                      label="Comments"
                      name="comments"
                      type="textarea"
                      rows="3"
                      icon={faMessage}
                    />
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Form>
        </FormProvider>
      </Card>

      <AddItemModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        onSave={handleModalSave}
        item={currentItem}
        isEditing={isEditing}
        itemlist={itemlist}
        quantityTypeList={quantityTypeList}
      />
    </Fragment>
  );
};

export default memo(AddOrder);
