import React, { Fragment, useState, useEffect, memo } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Row,
  Col,
  Button,
  Form,
  Alert,
  Table,
} from "reactstrap";
import {
  useUpdateFoundItemMutation,
  useGetFoundItemQuery,
} from "@src/redux/found-item/list";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "@src/components/shared/Loader";
import { TextInput, SelectInput } from "@src/components/inputs";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import { Plus, Edit, AlertCircle, X } from "react-feather";
import LoadingButton from "@src/components/shared/LoadingButton";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import AddFoundItemModal from "./AddFoundItemModal";
import CalendarInput from "@src/components/inputs/CalendarInput";
import {
  faCalendar,
  faPlane,
  faHashtag,
  faBuilding,
  faBookmark,
  faAddressCard,
  faMobileScreen,
} from "@fortawesome/free-solid-svg-icons";
import { useGetStationListQuery } from "@src/redux/station/station";
import { useGetItemTypeListQuery } from "@src/redux/found-item/item-type";
import { format } from "date-fns";

const itemsFoundSchema = yup.object().shape({
  id: yup.number().nullable(),
  itemTypeId: yup.string().required("Item type is required"),
  description: yup.string().nullable(),
  kind: yup.string().nullable(),
  amount: yup
    .number()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === "" ? null : value;
    })
    .typeError("Amount must be a number")
    .test("is-decimal", "Amount must be a float", (value) => {
      return value === null || Number(value) === value;
    }),
});

const foundItemSchema = yup.object().shape({
  flightNumber: yup
    .string()
    .nullable()
    .transform((value, originalValue) =>
      String(originalValue).trim() === "" ? null : originalValue
    )
    .matches(
      /^[1-9]\d{0,3}$/,
      "Flight number must be a number between 1 and 9999"
    )
    .notRequired(),

  flightDate: yup
    .string()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === "" ||
        (Array.isArray(originalValue) && originalValue.length === 0)
        ? null
        : originalValue;
    })
    .max(new Date(), "Flight date cannot be in the future.")
    .notRequired(),
  receivedDate: yup
    .date()
    .max(new Date(), "Received date cannot be in the future.")
    .required("Received date is required"),
  seatNumber: yup.string().nullable(),
  fromSector: yup.string().nullable(),
  toSector: yup.string().nullable(),
  bookingReference: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  passengerName: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  contactInfo: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  comments: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  itemsFound: yup
    .array()
    .of(itemsFoundSchema)
    .min(1, "At least one item must be added"),
});

const UpdateFoundItem = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [updateFoundItemMutation, { isLoading: isUpdateLoading }] =
    useUpdateFoundItemMutation();
  const { data: foundItemData, isLoading: isFetching } =
    useGetFoundItemQuery(id);
  const { data: stationList } = useGetStationListQuery();
  const { data: itemTypeList } = useGetItemTypeListQuery();

  const formMethods = useForm({
    resolver: yupResolver(foundItemSchema),
    mode: "onSubmit",
    defaultValues: {
      flightNumber: "",
      flightDate: "",
      receivedDate: "",
      seatNumber: "",
      fromSector: "",
      toSector: "",
      bookingReference: "",
      passengerName: "",
      contactInfo: "",
      comments: "",
      itemsFound: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: formMethods.control,
    name: "itemsFound",
  });

  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = formMethods;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (foundItemData) {
      formMethods.reset({
        flightNumber: foundItemData.flightNumber || "",
        flightDate: format(new Date(foundItemData.flightDate), "yyyy-MM-dd"),
        receivedDate: format(
          new Date(foundItemData.receivedDate),
          "yyyy-MM-dd"
        ),
        seatNumber: foundItemData.seatNumber || "",
        fromSector: foundItemData.fromSector || "",
        toSector: foundItemData.toSector || "",
        bookingReference: foundItemData.bookingReference || "",
        passengerName: foundItemData.passengerName || "",
        contactInfo: foundItemData.contactInfo || "",
        comments: foundItemData.comments || "",
        itemsFound: foundItemData.itemsFound.map((item) => ({
          ...item,
          id: item.id,
          itemTypeId: item.itemTypeId,
          description: item.description,
          kind: item.kind,
          amount: item.amount || 0,
        })),
      });
    }
  }, [foundItemData, formMethods]);

  const handleFormSubmit = async (formData) => {
    await foundItemSchema.validate(formData, { abortEarly: false });
    const mappedData = {
      id,
      flightNumber: formData.flightNumber,
      flightDate: format(new Date(formData.flightDate), "yyyy-MM-dd"),
      receivedDate: format(new Date(formData.receivedDate), "yyyy-MM-dd"),
      seatNumber: formData.seatNumber,
      fromSector: formData.fromSector,
      toSector: formData.toSector,
      bookingReference: formData.bookingReference,
      passengerName: formData.passengerName,
      contactInfo: formData.contactInfo,
      comments: formData.comments,
      itemsFound: formData.itemsFound.map((item) => ({
        id: item.id || null,
        itemTypeId: parseInt(item.itemTypeId, 10),
        description: item.description,
        kind: item.kind,
        amount: item.amount === 0 ? null : parseFloat(item.amount),
      })),
    };

    await updateFoundItemMutation(mappedData)
      .unwrap()
      .then((fulfilled) => {
        SuccessToast("Found Item Updated Successfully!");
        navigate("/baggage-management/found-items/list");
      })
      .catch((rejected) => {});
  };

  const handleEditItem = (item, index) => {
    setCurrentItem({ ...item, index });
    setIsEditing(true);
    toggleModal();
  };

  const addItem = () => {
    setCurrentItem(null);
    setIsEditing(false);
    toggleModal();
  };

  const handleModalSave = (newItem) => {
    const itemsFound = formMethods.getValues("itemsFound");
    if (isEditing) {
      const updatedItems = itemsFound.map((item, idx) =>
        idx == currentItem.index ? newItem : item
      );
      formMethods.setValue("itemsFound", updatedItems);
    } else {
      formMethods.setValue("itemsFound", [...itemsFound, newItem]);
    }
    toggleModal();
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const getItemTypeName = (itemTypeId) => {
    const itemType = itemTypeList?.find((type) => type.id == itemTypeId);
    return itemType ? itemType.name : "Unknown Item Type";
  };

  const itemsFound = formMethods.watch("itemsFound");

  if (isFetching || isUpdateLoading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <BreadCrumbs pageName="updateFoundItem" pageTitle="Update Found Item" />
      <Card>
        <CardHeader className="border-bottom">
          <CardTitle tag="h4">Update Found Item</CardTitle>
        </CardHeader>
        <FormProvider {...formMethods}>
          <Form onSubmit={handleSubmit(handleFormSubmit)}>
            <CardBody>
              <div className="info-container">
                <Row>
                  <Col lg="6" md="6" className="mb-1">
                    <TextInput
                      label="Flight Number"
                      name="flightNumber"
                      icon={faPlane}
                    />
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <SelectInput
                      label="From Sector"
                      name="fromSector"
                      icon={faBuilding}
                      options={
                        stationList?.map((type) => ({
                          value: type.id,
                          label: type.id,
                        })) || []
                      }
                    />
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <CalendarInput
                      label="Flight Date"
                      name="flightDate"
                      icon={faCalendar}
                    />
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <SelectInput
                      label="To Sector"
                      name="toSector"
                      icon={faBuilding}
                      options={
                        stationList?.map((type) => ({
                          value: type.id,
                          label: type.id,
                        })) || []
                      }
                    />
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <CalendarInput
                      label="Received Date"
                      name="receivedDate"
                      icon={faCalendar}
                    />
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <TextInput
                      label="Passenger Name"
                      name="passengerName"
                      icon={faAddressCard}
                    />
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <TextInput
                      label="Seat Number"
                      name="seatNumber"
                      icon={faHashtag}
                    />
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <TextInput
                      label="Contact Info"
                      name="contactInfo"
                      icon={faMobileScreen}
                    />
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <TextInput
                      label="Booking Reference"
                      name="bookingReference"
                      icon={faBookmark}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" md="12" className="mb-1">
                    <hr />
                    <CardTitle
                      tag="h4"
                      className="mb-0 fw-bolder d-flex justify-content-between"
                    >
                      <span>
                        Items Found
                        <p className="text-muted m-0 font-small-2">
                          You must add at least one found item
                        </p>
                      </span>
                      <Button
                        outline
                        className="btn-icon"
                        color="warning"
                        onClick={addItem}
                      >
                        <Plus size={14} />
                        <span className="align-middle ms-25">Add Item</span>
                      </Button>
                    </CardTitle>

                    {itemsFound.length > 0 ? (
                      <Table responsive bordered className="mt-2">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Item Type</th>
                            <th>Description</th>
                            <th>Kind</th>
                            <th>Amount</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{getItemTypeName(item.itemTypeId)}</td>
                              <td>
                                {item.description ? item.description : "-"}
                              </td>
                              <td>{item.kind ? item.kind : "-"}</td>
                              <td>
                                {item.amount && item.amount !== 0
                                  ? item.amount
                                  : "-"}
                              </td>
                              <td>
                                <Button
                                  outline
                                  color="primary"
                                  className="me-1"
                                  onClick={() => handleEditItem(item, index)}
                                >
                                  <Edit size={14} />
                                </Button>
                                <Button
                                  outline
                                  color="danger"
                                  onClick={() => remove(index)}
                                >
                                  <X size={14} />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <p className="text-muted text-center">
                        No items found yet, please add at least one item.
                      </p>
                    )}

                    {errors.itemsFound && (
                      <>
                        <Alert color="danger">
                          <div className="alert-body">
                            <AlertCircle size={14} />
                            <span className="align-middle ms-50">
                              {errors.itemsFound.message}
                            </span>
                          </div>
                        </Alert>
                      </>
                    )}
                    <hr />
                  </Col>
                </Row>
              </div>
            </CardBody>
            <CardFooter>
              <LoadingButton
                loading={isUpdateLoading}
                type="submit"
                color="primary"
              >
                Update
              </LoadingButton>
              <Button
                outline
                type="button"
                color="warning"
                className="mx-1"
                onClick={() => {
                  navigate("/baggage-management/found-items/list");
                }}
              >
                Cancel
              </Button>
            </CardFooter>
          </Form>
        </FormProvider>
      </Card>

      <AddFoundItemModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        onSave={handleModalSave}
        item={currentItem}
        isEditing={isEditing}
        itemTypeList={itemTypeList}
      />
    </Fragment>
  );
};

export default memo(UpdateFoundItem);
