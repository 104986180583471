import React, { useState } from "react";
import * as yup from "yup";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Form,
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "reactstrap";
import {
  faPlane,
  faCalendar,
  faBoxes,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import {
  TextInput,
  SelectInput,
  CalendarInput,
  UploadFile,
  ChipsInput,
} from "@src/components/inputs";
import { useAddLeftBehindItemMutation } from "@src/redux/left-behind/list";
import { useGetFileUploadSettingListQuery } from "@src/redux/general/setting";
import { useGetStationListQuery } from "@src/redux/station/station";
import { useGetReasonListQuery } from "@src/redux/left-behind/reason";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import { format } from "date-fns";
import Loader from "@src/components/shared/Loader";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@src/components/shared/LoadingButton";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";

const AddLeftBehind = () => {
  const [addLeftBehindItem, { isLoading }] = useAddLeftBehindItemMutation();
  const { data: reasonList } = useGetReasonListQuery();
  const { data: fileUploadSettings } = useGetFileUploadSettingListQuery();
  const { data: stationList } = useGetStationListQuery();
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();

  const leftBehindSchema = yup.object().shape({
    flightNumber: yup.string().required(),
    flightDate: yup.date().required("Flight date is required."),
    destination: yup.string().required("Destination is required."),
    faultStation: yup.string().required("Fault station is required."),
    numberOfPieces: yup
      .number()
      .required("Number of pieces is required.")
      .positive("Number of pieces must be greater than 0.")
      .integer("Number of pieces must be an integer."),
    leftBehindReasonId: yup
      .number()
      .required("Left behind reason ID is required.")
      .positive("Left behind reason ID must be a positive number.")
      .integer("Left behind reason ID must be an integer."),
    tags: yup.array().of(yup.string()).nullable(),
    comments: yup.string().nullable(),
    files: yup
      .array()
      .min(1, "At least one file is required.")
      .required("At least one file is required."),
  });

  const formMethods = useForm({
    resolver: yupResolver(leftBehindSchema),
    mode: "onSubmit",
    defaultValues: {
      flightNumber: "",
      flightDate: "",
      destination: "",
      faultStation: "",
      numberOfPieces: 1,
      leftBehindReasonId: "",
      tags: [],
      comments: "",
      files: [],
    },
  });

  const handleFormSubmit = async (formData) => {
    const leftbehindData = new FormData();

    Object.entries(formData).forEach(([key, value]) => {
      if (key === "files") {
        files.forEach((file) => leftbehindData.append(key, file));
      } else {
        const formattedValue =
          key === "flightDate"
            ? format(new Date(value), "yyyy-MM-dd")
            : key === "tags"
            ? value.join(";")
            : value;

        leftbehindData.append(key, formattedValue);
      }
    });

    await addLeftBehindItem(leftbehindData)
      .unwrap()
      .then((fulfilled) => {
        SuccessToast("Left-Behind item added successfully!");
        navigate(`/baggage-management/left-behind/list`);
      })
      .catch((rejected) => {});
  };

  return (
    <>
      {isLoading && <Loader />}
      <BreadCrumbs pageName="addLeftBehind" pageTitle="Add Left-Behind" />
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <Row>
            <Col lg="12" md="12" xs="12">
              <Card>
                <CardHeader className="border-bottom d-flex justify-content-between align-items-center">
                  <CardTitle tag="h1" className="fw-bolder">
                    Add Left-Behind Item
                  </CardTitle>
                  <div>
                    <LoadingButton
                      loading={isLoading}
                      type="submit"
                      color="primary"
                      className="me-2"
                    >
                      Save
                    </LoadingButton>
                    <Button
                      type="reset"
                      color="warning"
                      onClick={() => {
                        formMethods.reset();
                        setFiles([]);
                      }}
                    >
                      Reset
                    </Button>
                  </div>
                </CardHeader>

                <CardBody>
                  <div className="info-container">
                    <br />
                    <Row>
                      <Col md={3}>
                        <div className="mb-2">
                          <TextInput
                            twoLines
                            label="Flight Number"
                            name="flightNumber"
                            icon={faPlane}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-2">
                          <CalendarInput
                            twoLines={true}
                            label="Flight Date"
                            name="flightDate"
                            icon={faCalendar}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-2">
                          <SelectInput
                            twoLines
                            label="Destination Station"
                            name="destination"
                            options={
                              stationList?.map((type) => ({
                                value: type.id,
                                label: type.id,
                              })) || []
                            }
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-2">
                          <SelectInput
                            twoLines
                            label="Fault Station"
                            name="faultStation"
                            options={
                              stationList?.map((type) => ({
                                value: type.id,
                                label: type.id,
                              })) || []
                            }
                          />
                        </div>
                      </Col>
                      <hr />

                      <Col md={3}>
                        <div className="mb-2">
                          <TextInput
                            twoLines
                            label="Number of Pieces"
                            name="numberOfPieces"
                            type="number"
                            icon={faBoxes}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-2">
                          <SelectInput
                            twoLines={true}
                            label="Reason"
                            name="leftBehindReasonId"
                            icon={faBoxes}
                            options={
                              reasonList?.map((type) => ({
                                value: type.id,
                                label: type.name,
                              })) || []
                            }
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-2">
                          <ChipsInput label="Tags" name="tags" />
                        </div>
                      </Col>
                      <Col md={8}>
                        <div className="mb-2">
                          <TextInput
                            twoLines
                            label="Comments"
                            name="comments"
                            icon={faComment}
                            type="textarea"
                            rows="5"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="12" md="12" xs="12">
              <Card>
                <CardHeader className="border-bottom">
                  <CardTitle tag="h5" className="mb-0">
                    <span className="section-label">Upload Files</span>{" "}
                    <span className="font-small-2 text-muted">
                      {" "}
                      (One file at least)
                    </span>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="info-container">
                    <br />
                    <Row>
                      <Col md={12}>
                        <UploadFile
                          fileUploadSettings={fileUploadSettings}
                          files={files}
                          setFiles={setFiles}
                          formMethods={formMethods}
                        />
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </>
  );
};

export default AddLeftBehind;
