import LZBaggageList from ".";
import AddLzBaggage from "./add";
import LzBaggageDetailsPage from "./details";
import UpdateLzBaggage from "./update";

const LZBaggageRoutes = [
  {
    path: "/lz-baggage/list",
    index: true,
    element: <LZBaggageList />,
  },
  {
    path: "/lz-baggage/list/add",
    index: true,
    element: <AddLzBaggage />,
  },
  {
    path: "/lz-baggage/list/:id",
    index: true,
    element: <LzBaggageDetailsPage />,
  },
  {
    path: "/lz-baggage/list/:id/update",
    index: true,
    element: <UpdateLzBaggage />,
  },
];

export default LZBaggageRoutes;
