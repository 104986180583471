import React, { useState } from "react";
import { Card, CardHeader, Button, ButtonGroup } from "reactstrap";
import {
  faTruck,
  faForward,
  faHammer,
  faEdit,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeliverModal from "./DeliverModal";
import ForwardModal from "./ForwardModal";
import DestroyModal from "./DestroyModal";
import { Link, useNavigate } from "react-router-dom";
import { useDeleteFoundItemMutation } from "@src/redux/found-item/list";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import ConfirmSweetAlert from "@src/components/shared/ConfirmSweetAlert";

const ActionToolbar = ({ item }) => {
  const navigate = useNavigate();
  const [deliverModelVisibility, setDeliverModelVisibility] = useState(false);
  const [fordardModelVisibility, setFordardModelVisibility] = useState(false);
  const [destroyModelVisibility, setDestroyModelVisibility] = useState(false);
  const [deleteFoundItem, { isLoading: loadingDeleteFoundItem }] =
    useDeleteFoundItemMutation();

  const handleDeleteClick = async () => {
    ConfirmSweetAlert({
      title: "Are you sure , you need to Delete this Found Item?",
      icon: "question",
      confirmButtonText: "Delete",
      showCancelButton: true,
      cancelButtonClasses: "btn btn-warning ms-1",
      cancelButtonText: "Cancel",
      onConfirm: async () => {
        await deleteFoundItem(item.id)
          .unwrap()
          .then((fulfilled) => {
            SuccessToast("Found Item deleted Successfully!");
            navigate(`/baggage-management/found-items/list`);
          })
          .catch((rejected) => {});
      },
    });
  };

  if (item.status != "Pending") return <></>;

  return (
    <>
      <Card>
        <CardHeader
          className="border-bottom p-1 flex flex-wrap gap-2"
          style={{ justifyContent: "right" }}
        >
          <ButtonGroup className="mb-1">
            <Button
              outline
              type="button"
              color="secondary"
              onClick={() => handleDeleteClick()}
            >
              <FontAwesomeIcon icon={faXmark} className="font-medium-1" />
              &nbsp; Delete
            </Button>
            <Button
              outline
              tag={Link}
              to={`/baggage-management/found-items/list/${item.id}/update`}
              type="button"
              color="secondary"
            >
              <FontAwesomeIcon icon={faEdit} className="font-medium-1" />
              &nbsp; Update
            </Button>
            <Button
              outline
              type="button"
              color="secondary"
              onClick={() => setFordardModelVisibility(true)}
            >
              <FontAwesomeIcon icon={faForward} className="font-medium-1" />
              &nbsp; Forward
            </Button>
            <Button
              outline
              type="button"
              color="secondary"
              onClick={() => setDestroyModelVisibility(true)}
            >
              <FontAwesomeIcon icon={faHammer} className="font-medium-1" />{" "}
              &nbsp; Destroy
            </Button>
            <Button
              outline
              type="button"
              onClick={() => setDeliverModelVisibility(true)}
              color="secondary"
            >
              <FontAwesomeIcon icon={faTruck} className="font-medium-1" />{" "}
              &nbsp; Deliver
            </Button>
          </ButtonGroup>
        </CardHeader>
      </Card>
      <DeliverModal
        visible={deliverModelVisibility}
        toggle={() => setDeliverModelVisibility(false)}
        item={item}
      />
      <ForwardModal
        visible={fordardModelVisibility}
        toggle={() => setFordardModelVisibility(false)}
        item={item}
      />
      <DestroyModal
        visible={destroyModelVisibility}
        toggle={() => setDestroyModelVisibility(false)}
        item={item}
      />
    </>
  );
};

export default ActionToolbar;
