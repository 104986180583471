import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Col,
  Row,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { TextInput } from "@src/components/inputs";
import { useForwardFoundItemMutation } from "@src/redux/found-item/list";
import { faTag, faCalendar, faPlane } from "@fortawesome/free-solid-svg-icons";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import LoadingButton from "@src/components/shared/LoadingButton";
import CalendarInput from "@src/components/inputs/CalendarInput";
import { format } from "date-fns";

const ForwardModal = ({ visible, toggle, item }) => {
  const [forwardItem, { isLoading }] = useForwardFoundItemMutation();
  const forwardItemSchema = yup.object().shape({
    flightNumber: yup
      .string()
      .matches(
        /^[1-9]\d{0,3}$/,
        "Flight number must be a number between 1 and 9999"
      )
      .required("Flight number is required"),
    flightDate: yup
      .date()
      .max(new Date(), "Flight date cannot be in the future.")
      .required("Flight date is required"),
    handoverNumber: yup.string().required(),
  });

  const formMethods = useForm({
    resolver: yupResolver(forwardItemSchema),
    mode: "onSubmit",
    defaultValues: {
      flightNumber: "",
      flightDate: "",
      handoverNumber: "",
    },
  });

  useEffect(() => {
    if (visible) {
      formMethods.reset({
        flightNumber: "",
        flightDate: "",
        handoverNumber: "",
      });
    }
  }, [visible, formMethods]);

  const handleFormSubmit = async (formData) => {
    await forwardItem({
      id: item.id,
      flightNumber: formData.flightNumber,
      flightDate: format(new Date(formData.flightDate), "yyyy-MM-dd"),
      handoverNumber: formData.handoverNumber,
    })
      .unwrap()
      .then((fulfilled) => {
        SuccessToast("Found Item Forwarded successfully!");
        toggle(false);
      })
      .catch((rejected) => {});
  };

  return (
    <Modal isOpen={visible} toggle={toggle} className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>Forward Found Item</ModalHeader>
          <ModalBody>
            <Row>
              <hr />
              <Col md={12}>
                <div className="mb-1">
                  <TextInput
                    twoLines={true}
                    label="Flight Number"
                    name="flightNumber"
                    icon={faPlane}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-1">
                  <CalendarInput
                    twoLines={true}
                    label="Flight Date"
                    name="flightDate"
                    icon={faCalendar}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-1">
                  <TextInput
                    twoLines={true}
                    label="Handover Number"
                    name="handoverNumber"
                    icon={faTag}
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <LoadingButton loading={isLoading} type="submit" color="primary">
              Save
            </LoadingButton>
            <Button type="button" color="warning" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default ForwardModal;
