import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";

const lzActionTypes = [
  { value: "added", label: "Added", severity: "info" },
  { value: "updated", label: "Updated", severity: "info" },
  {
    value: "deliveredToPassenger",
    label: "To Pax",
    severity: "success",
  },
  {
    value: "deliveredToCharity",
    label: "To Charity",
    severity: "primary",
  },
  { value: "destroyed", label: "Destroyed", severity: "danger" },
  { value: "forwarded", label: "Forwarded", severity: "warning" },

  {
    value: "revertedToPending",
    label: "Reverted",
    severity: "info",
  },
];
const getActionTypeSeverity = (type) => {
  const action = lzActionTypes.find((action) => action.value === type);
  return action?.severity || "info";
};

const getActionTypeLabel = (type) => {
  const action = lzActionTypes.find((action) => action.value === type);
  return action?.label || type;
};

const actionTypeBodyTemplate = (rowData) => (
  <Tag
    value={getActionTypeLabel(rowData.actionType)}
    severity={getActionTypeSeverity(rowData.actionType)}
    className="text-capitalize"
  />
);
const actionTypeItemTemplate = ({ label, value }) => {
  return (
    <span className="text-capitalize">
      <Tag value={label} severity={getActionTypeSeverity(value)} />
    </span>
  );
};

const actionTypeFilterTemplate = (options) => {
  return (
    <Dropdown
      value={options.value}
      options={lzActionTypes}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={actionTypeItemTemplate}
      placeholder="Select One"
      className="p-column-filter"
      showClear
    />
  );
};

export const LzBaggageLogsColumns = () => [
  {
    header: "LZ Baggage ID",
    field: "lzBaggageId",
    filter: true,
    sortable: true,
    bodyStyle: { width: "15%" },
  },
  {
    header: "Action Type",
    field: "actionType",
    filter: true,
    body: actionTypeBodyTemplate,
    filterElement: actionTypeFilterTemplate,
    bodyStyle: { width: "20%" },
  },
  {
    header: "Description",
    field: "description",
    filter: false,
    bodyStyle: { width: "50%" },
  },
];

export default LzBaggageLogsColumns;
