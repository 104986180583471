import React from "react";
import { Col, Row } from "reactstrap";
import { formatDateTime } from "@src/utility/Utils";

const GeneralInfo = ({ orderData }) => {
  return (
    <Row>
      <Col md={6}>
        {" "}
        <Row>
          <Col tag="dt" sm="4" className="fw-bolder mb-1">
            Number:
          </Col>
          <Col tag="dd" sm="8" className="mb-1">
            {orderData?.id}
          </Col>
          <Col tag="dt" sm="4" className="fw-bolder mb-1">
            Station:
          </Col>
          <Col tag="dd" sm="8" className="mb-1">
            {orderData?.station}
          </Col>
        </Row>
      </Col>
      <Col md={6}>
        {" "}
        <Row>
          <Col tag="dt" sm="5" className="fw-bolder mb-1">
            Total Weight <span class="font-small-2 text-muted">(kg)</span>:
          </Col>
          <Col tag="dd" sm="7" className="mb-1">
            {Number.isInteger(orderData?.totalWeight)
              ? orderData?.totalWeight
              : orderData?.totalWeight.toFixed(2)}
          </Col>
          <Col tag="dt" sm="5" className="fw-bolder mb-1">
            Created On <span class="font-small-2 text-muted">(UTC)</span>:
          </Col>
          <Col tag="dd" sm="7" className="mb-1">
            {orderData?.createdOnUtc &&
              formatDateTime(new Date(orderData?.createdOnUtc))}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default GeneralInfo;
