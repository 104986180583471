import React from "react";
import { Button, DropdownItem } from "reactstrap";
import { faXmark, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDeleteLzBaggageItemMutation } from "@src/redux/lz-baggage/list";
import { useNavigate } from "react-router-dom";
import ConfirmSweetAlert from "@src/components/shared/ConfirmSweetAlert";
import { SuccessToast } from "@src/components/shared/SuccessToast";

const DeleteLzBaggage = ({ item, listButton = false }) => {
  const navigate = useNavigate();
  const [deleteLzBaggage] = useDeleteLzBaggageItemMutation();

  const handleDeleteClick = async (item) => {
    ConfirmSweetAlert({
      title: `Are you sure , you need to Delete Lz-Baggage number (${item.moduleNumber})?`,
      icon: "question",
      confirmButtonText: "Delete",
      showCancelButton: true,
      cancelButtonClasses: "btn btn-warning ms-1",
      cancelButtonText: "Cancel",
      onConfirm: async () => {
        await deleteLzBaggage(item.id)
          .unwrap()
          .then((fulfilled) => {
            SuccessToast(
              `Lz-Baggage with number (${item.moduleNumber})  has been deleted Successfully!`
            );
            navigate(`/baggage-management/lz-baggage/list`);
            toggle(false);
          })
          .catch((rejected) => {});
      },
    });
  };

  if (listButton)
    return (
      <>
        <DropdownItem
          onClick={() => {
            handleDeleteClick({ ...item });
          }}
        >
          <FontAwesomeIcon icon={faTrashCan} className="font-medium-3" />
          <span className="align-middle ms-50">Delete</span>
        </DropdownItem>
      </>
    );

  return (
    <>
      <Button
        outline
        type="button"
        color="secondary"
        onClick={() => handleDeleteClick(item)}
      >
        <FontAwesomeIcon icon={faXmark} className="font-medium-1" />
        &nbsp; Delete
      </Button>
    </>
  );
};

export default DeleteLzBaggage;
