import React, { Fragment, useState, memo } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  InputGroup,
} from "reactstrap";
import { useGetWrongLoadWrongTagQuery } from "@src/redux/wrongload-wrongtag/list";
import { useParams } from "react-router-dom";
import Loader from "@src/components/shared/Loader";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlane,
  faBoxes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Tag } from "primereact/tag";
import FileDetailView from "@src/components/shared/FileDetailView";

const WrongLoadWrongTagDetails = () => {
  const { id } = useParams();
  const { data, isFetching, error } = useGetWrongLoadWrongTagQuery(id);
  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const getStatusSeverity = (status) => {
    switch (status) {
      case "wrl":
        return "info";
      case "wrt":
        return "warning";
      default:
        return "danger";
    }
  };

  if (isFetching) {
    return <Loader />;
  }

  if (error) {
    return <p>Error loading details: {error.message}</p>;
  }

  return (
    <Fragment>
      <BreadCrumbs
        pageName="WrongLoadWrongTagDetails"
        pageTitle="Wrong Load/Wrong Tag Details"
      />
      {data ? (
        <Row>
          <Col lg="3" md="3" xs="12">
            <Card>
              <CardHeader className="border-bottom">
                <CardTitle tag="h1" className="fw-bolder">
                  <span className="text-primary">
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </span>
                  Wrong Load/Wrong Tag Details
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="info-container">
                  <br />
                  <Row>
                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Flight Number:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      <span className="text-primary">
                        <FontAwesomeIcon icon={faPlane} />
                      </span>{" "}
                      {data.flightNumber}
                    </Col>
                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Flight Date:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      {data.flightDate}
                    </Col>
                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Destination:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      {data.destination}
                    </Col>
                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Fault Station:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      {data.faultStation}
                    </Col>
                    <CardTitle
                      tag="h5"
                      className="fw-bolder border-bottom pb-50 mb-1 mt-1"
                    >
                      <span className="section-label">Other Information</span>
                    </CardTitle>
                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Status:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      <InputGroup>
                        <Tag
                          value={data.status.toUpperCase()}
                          severity={getStatusSeverity(data.status)}
                        />
                      </InputGroup>
                    </Col>
                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Total Pieces:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      {data.totalPieces || "-"}
                    </Col>

                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      OHD:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      {data.ohd || "-"}
                    </Col>

                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Claim Number:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      {data.claimNumber || "-"}
                    </Col>

                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      Tag Number:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      {data.tagNumber || "-"}
                    </Col>

                    <Col tag="dt" sm="6" className="fw-bolder mb-1">
                      WRT Tag Number:
                    </Col>
                    <Col tag="dd" sm="6" className="mb-1">
                      {data.wrtTagNumber || "-"}
                    </Col>

                    <Col tag="dt" sm="9" className="fw-bolder mb-1">
                      WRT Tag Booking Reference:
                    </Col>
                    <Col tag="dd" sm="3" className="mb-1">
                      {data.wrtTagBookingReference || "-"}
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="9" md="9" xs="12">
            <Nav tabs>
              <NavItem>
                <NavLink
                  active={activeTab === "1"}
                  onClick={() => toggleTab("1")}
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="me-1" />
                  Passenger Sector
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === "2"}
                  onClick={() => toggleTab("2")}
                >
                  <FontAwesomeIcon icon={faBoxes} className="me-1" />
                  Files
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className="py-2">
              <TabPane tabId="1">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Passenger Sector</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col tag="dt" sm="3" className="fw-bolder mb-1">
                        Pax To Flight Number:
                      </Col>
                      <Col tag="dd" sm="9" className="mb-1">
                        <span className="text-primary">
                          <FontAwesomeIcon icon={faPlane} />
                        </span>{" "}
                        {data.paxToFlightNumber || "-"}
                      </Col>
                      <Col tag="dt" sm="3" className="fw-bolder mb-1">
                        Pax To Flight Date:
                      </Col>
                      <Col tag="dd" sm="9" className="mb-1">
                        {data.paxToFlightDate || "-"}
                      </Col>
                      <Col tag="dt" sm="3" className="fw-bolder mb-1">
                        Pax Checked To:
                      </Col>
                      <Col tag="dd" sm="9" className="mb-1">
                        {data.paxCheckedTo || "-"}
                      </Col>
                      <Col tag="dt" sm="3" className="fw-bolder mb-1">
                        Pax Booking Reference:
                      </Col>
                      <Col tag="dd" sm="9" className="mb-1">
                        {data.paxBookingReference || "-"}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="2">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Associated Files</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <FileDetailView
                      files={data?.files}
                      category="WrongLoadWrongTag"
                    />
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <p>No data available.</p>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default memo(WrongLoadWrongTagDetails);
