import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Col,
  Row,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { TextInput, CalendarInput } from "@src/components/inputs";
import { useDestroyLzBaggageItemMutation } from "@src/redux/lz-baggage/list";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import LoadingButton from "@src/components/shared/LoadingButton";
import {
  faCalendar,
  faTrash,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";

const DestroyModal = ({ isVisible, toggle, baggageData }) => {
  const [destroyLzBaggage, { isLoading }] = useDestroyLzBaggageItemMutation();

  const destroySchema = yup.object().shape({
    destroyReason: yup.string().required("Destroy Reason is required"),
    destroyDate: yup.date().required("Destroy Date is required"),
    comments: yup.string().nullable(),
  });

  const formMethods = useForm({
    resolver: yupResolver(destroySchema),
    mode: "onSubmit",
    defaultValues: {
      destroyReason: "",
      destroyDate: "",
      comments: "",
    },
  });

  useEffect(() => {
    if (isVisible) {
      formMethods.reset();
    }
  }, [isVisible, formMethods]);

  const handleFormSubmit = async (formData) => {
    try {
      const command = {
        id: baggageData.id,
        DestroyReason: formData.destroyReason,
        DestroyDate: format(new Date(formData.destroyDate), "yyyy-MM-dd"),
        Comments: formData.comments || null,
      };

      await destroyLzBaggage(command).unwrap();
      SuccessToast("Luggage successfully destroyed!");
      toggle(); // Close the modal
    } catch (error) {
      console.error("Destroying luggage failed:", error);
    }
  };

  return (
    <Modal isOpen={isVisible} toggle={toggle} size="lg">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>Destroy Luggage</ModalHeader>
          <ModalBody>
            <Row>
              <Col md={6}>
                <div className="mb-2">
                  <CalendarInput
                    twoLines
                    label="Destroy Date"
                    name="destroyDate"
                    icon={faCalendar}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-2">
                  <TextInput
                    twoLines
                    label="Destroy Reason"
                    name="destroyReason"
                    icon={faTrash}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="mb-2">
                  <TextInput
                    label="Comments (Optional)"
                    name="comments"
                    type="textarea"
                    rows="4"
                    icon={faComment}
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <LoadingButton loading={isLoading} type="submit" color="primary">
              Save
            </LoadingButton>
            <Button type="button" color="warning" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default DestroyModal;
