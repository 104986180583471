import { formatDateOnly } from "@src/utility/Utils";
import { Calendar } from "primereact/calendar";
import { format } from "date-fns";
import {
  faEye,
  faForward,
  faEdit,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, UncontrolledTooltip } from "reactstrap";

const leftBehindColumns = (handleDeleteClick) => {
  const flightDateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value ? new Date(options.value) : null}
        onChange={(e) =>
          options.filterCallback(format(e.value, "yyyy-MM-dd"), options.index)
        }
        dateFormat="dd/mm/yy"
        placeholder="dd/mm/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const flightDateBodyTemplate = (rowData) => {
    return formatDateOnly(new Date(rowData.flightDate));
  };
  const actionsBodyTemplate = (row, handleDeleteClick) => {
    return (
      <div className="d-flex" style={{ justifyContent: "center" }}>
        <a
          style={{ padding: ".515rem .536rem" }}
          id="details"
          outline
          className="btn  btn-icon rounded-circle btn-outline-dark"
          color="primary"
          href={`/baggage-management/left-behind/list/${row.id}`}
          target="_self"
        >
          {" "}
          <FontAwesomeIcon icon={faEye} className="font-medium-3" />
        </a>
        <UncontrolledTooltip target="details" placement="top">
          Details
        </UncontrolledTooltip>
        <a
          style={{ padding: ".515rem .536rem" }}
          id="forward"
          outline
          className="btn  btn-icon rounded-circle btn-outline-dark ms-1"
          color="primary"
          href={`/baggage-management/left-behind/list/${row.id}/forward`}
          target="_self"
        >
          {" "}
          <FontAwesomeIcon icon={faForward} className="font-medium-3" />
        </a>
        <UncontrolledTooltip target="forward" placement="top">
          Forward
        </UncontrolledTooltip>
        <Button
          style={{ padding: ".515rem .536rem" }}
          id="update"
          className="btn-icon rounded-circle ms-1"
          outline
          color="dark"
          href={`/baggage-management/left-behind/list/${row.id}/update`}
        >
          <span className="text-uppercase">
            <FontAwesomeIcon icon={faEdit} className="font-medium-3" />
          </span>
        </Button>
        <UncontrolledTooltip target="update" placement="top">
          Update
        </UncontrolledTooltip>
        <Button
          style={{ padding: ".515rem .536rem" }}
          id="delete"
          className="btn-icon rounded-circle ms-1"
          outline
          color="danger"
          onClick={() => {
            handleDeleteClick({ ...row });
          }}
        >
          <span className="text-uppercase">
            <FontAwesomeIcon icon={faXmark} className="font-medium-3" />
          </span>
        </Button>
        <UncontrolledTooltip target="delete" placement="top">
          Delete
        </UncontrolledTooltip>
      </div>
    );
  };
  return [
    {
      header: "Flight Number",
      filter: true,
      field: "flightNumber",
      sortable: false,
      dataType: "numeric",
    },
    {
      header: "Flight Date",
      filter: true,
      field: "flightDate",
      sortable: false,
      dataType: "date",
      body: flightDateBodyTemplate,
      filterElement: flightDateFilterTemplate,
      filterField: "flightDate",
    },
    {
      header: "Destination",
      filter: true,
      field: "destination",
      sortable: false,
    },
    {
      header: "Fault Station",
      filter: true,
      field: "faultStation",
      sortable: false,
    },
    {
      header: "Number Of Pieces",
      filter: true,
      field: "numberOfPieces",
      sortable: false,
      dataType: "numeric",
    },
    {
      header: "Remaining Pieces",
      filter: true,
      field: "remainingPieces",
      sortable: false,
    },
    {
      header: "Reason",
      filter: true,
      field: "reason",
      sortable: false,
    },
    {
      header: "Actions",
      filter: false,
      field: "actions",
      headerStyle: { textAlign: "center" },
      body: (rowData) => actionsBodyTemplate(rowData, handleDeleteClick),
    },
  ];
};

export default leftBehindColumns;
