import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Button,
  Form,
  Col,
  Row,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { TextInput } from "@src/components/inputs";
import { useAddItemMutation } from "@src/redux/stock/item";
import { useGetQuantityTypeListQuery } from "@src/redux/stock/quantityType";
import {
  faTag,
  faMessage,
  faBoxOpen,
  faBox,
  faWeightScale,
  faBoxesPacking,
} from "@fortawesome/free-solid-svg-icons";
import { SelectInput } from "@src/components/inputs";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import LoadingButton from "@src/components/shared/LoadingButton";

const ItemModal = ({ visible, toggle }) => {
  const [addStockItem, { isLoading }] = useAddItemMutation();
  const { data: quantityTypes, error } = useGetQuantityTypeListQuery();
  const itemsSchema = yup.object().shape({
    name: yup
      .string()
      .required("Name is required")
      .max(50, "Name must not exceed 50 characters"),
    piecesPerBox: yup
      .number()
      .required("Pieces per box is required")
      .positive("Pieces per box must be greater than 0"),
    boxWeight: yup
      .number()
      .required("Box weight is required")
      .positive("Box weight must be greater than 0"),
    quantity: yup
      .number()
      .required("Quantity is required")
      .min(0, "Quantity must be greater than or equal to 0"),
    minimumBoxLimit: yup
      .number()
      .required("Minimum box limit is required")
      .positive("Minimum box limit must be greater than 0"),
    quantityTypeId: yup.number().required("Quantity type is required"),
    description: yup.string().nullable(),
  });

  const formMethods = useForm({
    resolver: yupResolver(itemsSchema),
    mode: "onSubmit",
    defaultValues: {
      name: "",
      piecesPerBox: "",
      boxWeight: "",
      quantity: "",
      minimumBoxLimit: "",
      quantityTypeId: "",
      description: "",
    },
  });

  useEffect(() => {
    if (visible) {
      formMethods.reset({
        name: "",
        piecesPerBox: "",
        boxWeight: "",
        quantity: "",
        minimumBoxLimit: "",
        quantityTypeId: "",
        description: "",
      });
    }
  }, [visible, formMethods]);

  const handleFormSubmit = async (formData) => {
    await addStockItem({
      name: formData.name,
      piecesPerBox: formData.piecesPerBox,
      boxWeight: formData.boxWeight,
      quantity: formData.quantity,
      minimumBoxLimit: formData.minimumBoxLimit,
      quantityTypeId: formData.quantityTypeId,
      description: formData.description,
    })
      .unwrap()
      .then((fulfilled) => {
        SuccessToast("Stock Item added successfully!");
        toggle(false);
      })
      .catch((rejected) => {});
  };

  return (
    <Modal isOpen={visible} toggle={toggle} size="lg" className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>Add Stock Item</ModalHeader>
          <ModalBody>
            <Row>
              <Col md={6}>
                {" "}
                <FormGroup>
                  <TextInput label="Name" name="name" icon={faTag} />
                </FormGroup>
                <FormGroup>
                  <SelectInput
                    label="Quantity Type"
                    name="quantityTypeId"
                    options={
                      quantityTypes?.map((type) => ({
                        value: type.id,
                        label: type.name,
                      })) || []
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <TextInput
                    label="Pieces Per Box"
                    name="piecesPerBox"
                    icon={faBoxOpen}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                {" "}
                <FormGroup>
                  <TextInput
                    label={
                      <>
                        Box Weight
                        <span className="font-small-2 text-muted"> (kg)</span>
                      </>
                    }
                    placeholder="Box Weight (kg)"
                    name="boxWeight"
                    icon={faWeightScale}
                  />
                </FormGroup>
                <FormGroup>
                  <TextInput
                    label="Initial Quantity"
                    name="quantity"
                    icon={faBoxesPacking}
                  />
                </FormGroup>
                <FormGroup>
                  <TextInput
                    label="Min Box Limit"
                    name="minimumBoxLimit"
                    icon={faBox}
                  />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <TextInput
                twoLines={true}
                label="Description"
                name="description"
                type="textarea"
                rows="5"
                icon={faMessage}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <LoadingButton loading={isLoading} type="submit" color="primary">
              Add
            </LoadingButton>
            <Button type="button" color="warning" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default ItemModal;
