import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Form,
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "reactstrap";
import {
  faPlane,
  faCalendar,
  faBoxes,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import {
  TextInput,
  UploadFile,
  CalendarInput,
  ChipsInput,
  SelectInput,
} from "@src/components/inputs";
import {
  useUpdateLeftBehindItemMutation,
  useGetLeftBehindItemQuery,
} from "@src/redux/left-behind/list";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import { format } from "date-fns";
import Loader from "@src/components/shared/Loader";
import { useNavigate, useParams } from "react-router-dom";
import LoadingButton from "@src/components/shared/LoadingButton";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import { useLazyDownloadFileQuery } from "@src/redux/general/file";
import FileDetailView from "@src/components/shared/FileDetailView";
import { useGetFileUploadSettingListQuery } from "@src/redux/general/setting";
import { useGetStationListQuery } from "@src/redux/station/station";
import { useGetReasonListQuery } from "@src/redux/left-behind/reason";

const UpdateLeftBehind = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: leftBehindItem,
    isLoading: isLoadingItem,
    error,
  } = useGetLeftBehindItemQuery(id);
  const [updateLeftBehindItem, { isLoading: isUpdating }] =
    useUpdateLeftBehindItemMutation();
  const [downloadFile] = useLazyDownloadFileQuery();
  const { data: fileUploadSettings } = useGetFileUploadSettingListQuery();
  const { data: stationList } = useGetStationListQuery();
  const { data: reasonList } = useGetReasonListQuery();

  const [files, setFiles] = useState([]);
  const [forwardedPiecesSum, setForwardedPiecesSum] = useState(0);

  useEffect(() => {
    if (leftBehindItem) {
      const sumForwardedPieces = leftBehindItem.forwards.reduce(
        (sum, forward) => sum + forward.forwardedPieces,
        0
      );
      setForwardedPiecesSum(sumForwardedPieces);

      formMethods.reset({
        flightNumber: leftBehindItem.flightNumber,
        flightDate: leftBehindItem.flightDate,
        destination: leftBehindItem.destination,
        faultStation: leftBehindItem.faultStation,
        leftBehindReasonId: leftBehindItem.leftBehindReasonId,
        numberOfPieces: leftBehindItem.numberOfPieces,
        tags: leftBehindItem.tags ? leftBehindItem.tags.split(";") : [],
        comments: leftBehindItem.comments || "",
      });
    }
  }, [leftBehindItem, formMethods]);

  const leftBehindSchema = yup.object().shape({
    flightNumber: yup.string().required("Flight number is required."),
    flightDate: yup.date().required("Flight date is required."),
    destination: yup.string().required("Destination is required."),
    faultStation: yup.string().required("Fault station is required."),
    leftBehindReasonId: yup
      .number()
      .required("Left behind reason ID is required.")
      .positive("Left behind reason ID must be a positive number.")
      .integer("Left behind reason ID must be an integer."),
    numberOfPieces: yup
      .number()
      .required("Number of pieces is required.")
      .positive("Number of pieces must be greater than 0.")
      .integer("Number of pieces must be an integer.")
      .min(
        forwardedPiecesSum,
        `Number of pieces must be at least ${forwardedPiecesSum}`
      ),
    tags: yup.array().of(yup.string()).nullable(),
    comments: yup.string().nullable(),
    files: yup.array().nullable(),
  });

  const formMethods = useForm({
    resolver: yupResolver(leftBehindSchema),
    mode: "onSubmit",
    defaultValues: {
      flightNumber: "",
      flightDate: "",
      destination: "",
      faultStation: "",
      numberOfPieces: 1,
      leftBehindReasonId: "",
      tags: [],
      comments: "",
      files: [],
    },
  });

  const handleFormSubmit = async (formData) => {
    const leftbehindData = new FormData();
    leftbehindData.append("id", id);
    Object.entries(formData).forEach(([key, value]) => {
      if (key === "files") {
        files.forEach((file) => leftbehindData.append(key, file));
      } else {
        const formattedValue =
          key === "flightDate"
            ? format(new Date(formData.flightDate), "yyyy-MM-dd")
            : key === "tags"
            ? formData.tags
              ? formData.tags.join(";")
              : ""
            : value;

        leftbehindData.append(key, formattedValue);
      }
    });

    await updateLeftBehindItem({ id, formData: leftbehindData })
      .unwrap()
      .then((fulfilled) => {
        SuccessToast("Left-Behind item updated successfully!");
        navigate(`/baggage-management/left-behind/list`);
      })
      .catch((rejected) => {});
  };

  const handleDownload = async (identifier) => {
    var category = "LeftBehind";
    const response = await downloadFile(
      `identifier=${identifier}&category=${category}`
    ).unwrap();

    const blob = new Blob([response], { type: response.type });
    saveAs(blob, identifier);
    SuccessToast(`File downloaded Successfully!`);
  };

  return (
    <>
      {(isLoadingItem || isUpdating) && <Loader />}
      <BreadCrumbs pageName="updateLeftBehind" pageTitle="Update Left-Behind" />
      {leftBehindItem ? (
        <FormProvider {...formMethods}>
          <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
            <Row>
              <Col lg="12" md="12" xs="12">
                <Card>
                  <CardHeader className="border-bottom d-flex justify-content-between align-items-center">
                    <CardTitle tag="h1" className="fw-bolder">
                      Update Left-Behind Item
                    </CardTitle>
                    <div>
                      <LoadingButton
                        loading={isUpdating}
                        type="submit"
                        color="primary"
                        className="me-2"
                      >
                        Update
                      </LoadingButton>
                      <Button
                        type="reset"
                        color="warning"
                        onClick={() => formMethods.reset()}
                      >
                        Reset
                      </Button>
                    </div>
                  </CardHeader>

                  <CardBody>
                    <br />
                    <Row>
                      <Col md={3}>
                        <TextInput
                          twoLines
                          label="Flight Number"
                          name="flightNumber"
                          icon={faPlane}
                        />
                      </Col>
                      <Col md={3}>
                        <CalendarInput
                          twoLines
                          label="Flight Date"
                          name="flightDate"
                          icon={faCalendar}
                        />
                      </Col>
                      <Col md={3}>
                        <div className="mb-2">
                          <SelectInput
                            twoLines
                            label="Destination Station"
                            name="destination"
                            options={
                              stationList?.map((type) => ({
                                value: type.id,
                                label: type.id,
                              })) || []
                            }
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-2">
                          <SelectInput
                            twoLines
                            label="Fault Station"
                            name="faultStation"
                            options={
                              stationList?.map((type) => ({
                                value: type.id,
                                label: type.id,
                              })) || []
                            }
                          />
                        </div>
                      </Col>
                      <hr />
                      <Col md={3}>
                        <TextInput
                          twoLines
                          label={
                            <>
                              Number of Pieces{" "}
                              <span className="text-muted font-small-2">
                                (Forwarded pieces are {forwardedPiecesSum})
                              </span>
                            </>
                          }
                          name="numberOfPieces"
                          type="number"
                          icon={faBoxes}
                        />
                      </Col>
                      <Col md={3}>
                        <div className="mb-2">
                          <SelectInput
                            twoLines={true}
                            label="Reason"
                            name="leftBehindReasonId"
                            icon={faBoxes}
                            options={
                              reasonList?.map((type) => ({
                                value: type.id,
                                label: type.name,
                              })) || []
                            }
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <ChipsInput label="Tags" name="tags" />
                      </Col>
                      <Col md={8}>
                        <TextInput
                          twoLines
                          label="Comments"
                          name="comments"
                          icon={faComment}
                          type="textarea"
                          rows="5"
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Card>
                <CardHeader>
                  <CardTitle tag="h5" className="mb-0">
                    Upload Files{" "}
                    <span className="font-small-2 text-muted">
                      {" "}
                      (You can upload new files)
                    </span>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Col md={12}>
                    <UploadFile
                      fileUploadSettings={fileUploadSettings}
                      files={files}
                      setFiles={setFiles}
                      formMethods={formMethods}
                    />
                    <CardTitle tag="h5" className="mb-0">
                      <span className="section-label">Existed files</span>
                    </CardTitle>
                    <FileDetailView
                      files={leftBehindItem?.files}
                      onDownload={handleDownload}
                    />
                  </Col>
                </CardBody>
              </Card>
            </Row>
          </Form>
        </FormProvider>
      ) : (
        <Row>
          <Col>
            <p>"No data available."</p>
          </Col>
        </Row>
      )}
    </>
  );
};

export default UpdateLeftBehind;
