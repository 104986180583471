import { mainApi } from "../mainApi";

export const settingApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getSettingList: builder.query({
      query: () => ({
        url: `/setting/list`,
        method: "GET",
      }),
      invalidatesTags: [{ type: "Settings", id: "List" }],
    }),
    updateSetting: builder.mutation({
      query: (data) => ({
        url: `/setting/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [{ type: "Settings", id: "List" }],
    }),
    getFileUploadSettingList: builder.query({
      query: () => ({
        url: `/setting/file-upload`,
        method: "GET",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSettingListQuery,
  useUpdateSettingMutation,
  useGetFileUploadSettingListQuery,
} = settingApi;
