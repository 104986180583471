import React from "react";
import { Spinner, Button } from "reactstrap";

function LoadingButton({ loading = false, children, ...props }) {
  return (
    <Button disabled={loading} {...props}>
      {loading && <Spinner className="ml-1" animation="grow" size={"sm"} />}{" "}
      {children}
    </Button>
  );
}

export default LoadingButton;
