import { mainApi } from "../mainApi";

export const possibleOwnersApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    addPossibleOwnerAction: builder.mutation({
      query: (data) => ({
        url: `/founditems/${data.foundItemId}/possibleOwners/${data.possibleOwnerId}/actions`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "FoundItemList", id: "Details" }],
    }),
    toggleOwner: builder.mutation({
      query: (data) => ({
        url: `/founditems/${data.foundItemId}/possibleOwners/toggleOwner`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [{ type: "FoundItemList", id: "Details" }],
    }),
    addPossibleOwners: builder.mutation({
      query: (data) => ({
        url: `/founditems/${data.foundItemId}/possibleOwners`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "FoundItemList", id: "Details" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddPossibleOwnerActionMutation,
  useAddPossibleOwnersMutation,
  useToggleOwnerMutation,
} = possibleOwnersApi;
