import { Calendar } from "primereact/calendar";
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";
import { formatDateOnly } from "@src/utility/Utils";
import { faEye, faEdit, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, UncontrolledTooltip } from "reactstrap";

const WrongLoadWrongTagColumns = (handleDeleteClick) => {
  const statusOptions = [
    { label: "WRL", value: "wrl" },
    { label: "WRT", value: "wrt" },
  ];

  const getStatusSeverity = (status) => {
    switch (status) {
      case "wrl":
        return "info";
      case "wrt":
        return "warning";
      default:
        return "danger"; // Unknown or other statuses
    }
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.status.toUpperCase()}
        severity={getStatusSeverity(rowData.status)}
        className="text-capitalize"
      />
    );
  };

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statusOptions}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        placeholder="Select Status"
        className="p-column-filter"
        showClear
      />
    );
  };

  const flightDateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value ? new Date(options.value) : null}
        onChange={(e) =>
          options.filterCallback(format(e.value, "yyyy-MM-dd"), options.index)
        }
        dateFormat="dd/mm/yy"
        placeholder="dd/mm/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const flightDateBodyTemplate = (rowData) => {
    return formatDateOnly(new Date(rowData.paxToFlightDate));
  };

  const actionsBodyTemplate = (row, handleDeleteClick) => {
    return (
      <div className="d-flex" style={{ justifyContent: "center" }}>
        <a
          style={{ padding: ".515rem .536rem" }}
          id="details"
          outline
          className="btn  btn-icon rounded-circle btn-outline-dark"
          color="primary"
          href={`/baggage-management/wrongload-wrongtag/list/${row.id}`}
          target="_self"
        >
          {" "}
          <FontAwesomeIcon icon={faEye} className="font-medium-3" />
        </a>
        <UncontrolledTooltip target="details" placement="top">
          Details
        </UncontrolledTooltip>
        <Button
          style={{ padding: ".515rem .536rem" }}
          id="update"
          className="btn-icon rounded-circle ms-1"
          outline
          color="dark"
          href={`/baggage-management/wrongload-wrongtag/list/${row.id}/update`}
        >
          <span className="text-uppercase">
            <FontAwesomeIcon icon={faEdit} className="font-medium-3" />
          </span>
        </Button>
        <UncontrolledTooltip target="update" placement="top">
          Update
        </UncontrolledTooltip>
        <Button
          style={{ padding: ".515rem .536rem" }}
          id="delete"
          className="btn-icon rounded-circle ms-1"
          outline
          color="danger"
          onClick={() => {
            handleDeleteClick({ ...row });
          }}
        >
          <span className="text-uppercase">
            <FontAwesomeIcon icon={faXmark} className="font-medium-3" />
          </span>
        </Button>
        <UncontrolledTooltip target="delete" placement="top">
          Delete
        </UncontrolledTooltip>
      </div>
    );
  };

  return [
    { header: "Number", field: "id", filter: true },
    { header: "Flight No", field: "flightNumber", filter: true },
    {
      header: "Flight Date",
      filter: true,
      field: "paxToFlightDate",
      sortable: false,
      dataType: "date",
      body: flightDateBodyTemplate,
      filterElement: flightDateFilterTemplate,
      filterField: "paxToFlightDate",
    },
    { header: "Destination", field: "destination", filter: true },
    { header: "Total Pieces", field: "totalPieces", filter: true },
    { header: "Fault Station", field: "faultStation", filter: true },
    {
      header: "Flight No To",
      field: "paxToFlightNumber",
      filter: true,
    },
    {
      header: "Flight To Date",
      field: "paxToFlightDate",
      filter: true,
      dataType: "date",
    },
    { header: "Checked To", field: "paxCheckedTo", filter: true },
    { header: "OHD", field: "ohd", filter: true },
    {
      header: "Status",
      field: "status",
      filter: true,
      body: statusBodyTemplate,
      filterElement: statusFilterTemplate,
    },
    { header: "Tag No", field: "tagNumber", filter: true },
    { header: "Claim No", field: "claimNumber", filter: true },
    {
      header: "Actions",
      body: (rowData) => actionsBodyTemplate(rowData, handleDeleteClick),
      headerStyle: { textAlign: "center" },
    },
  ];
};

export default WrongLoadWrongTagColumns;
