import { mainApi } from "../mainApi";

export const settingApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getFileUploadSettingList: builder.query({
      query: () => ({
        url: `/setting/file-upload`,
        method: "GET",
      }),
    }),
    downloadFile: builder.query({
      query: (param) => ({
        url: `/files/download?${param}`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
    getSsuriFile: builder.query({
      query: (param) => ({
        url: `/files/sasUri?${param}`,
        method: "GET",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetFileUploadSettingListQuery,
  useLazyDownloadFileQuery,
  useLazyGetSsuriFileQuery,
} = settingApi;
