import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Button,
  Form,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { TextInput } from "@src/components/inputs";
import LoadingButton from "@src/components/shared/LoadingButton";
import { useAddReasonMutation } from "@src/redux/left-behind/reason";
import { faTag, faMessage } from "@fortawesome/free-solid-svg-icons";
import { SuccessToast } from "@src/components/shared/SuccessToast";

const ReasonModal = ({ visible, toggle }) => {
  const [addReason, { isLoading }] = useAddReasonMutation();

  const reasonsSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().nullable(),
  });

  const formMethods = useForm({
    resolver: yupResolver(reasonsSchema),
    mode: "onSubmit",
    defaultValues: {
      name: "",
      description: "",
    },
  });

  useEffect(() => {
    if (visible) {
      formMethods.reset({
        name: "",
        description: "",
      });
    }
  }, [visible, formMethods]);

  const handleFormSubmit = async (formData) => {
    await addReason({
      name: formData.name,
      description: formData.description,
    })
      .unwrap()
      .then((fulfilled) => {
        SuccessToast("Left-Behind Reason added successfully!");
        toggle(false);
      })
      .catch((rejected) => {});
  };

  return (
    <Modal isOpen={visible} toggle={toggle} className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>Add Left-Behind Reason</ModalHeader>
          <ModalBody>
            <FormGroup>
              <TextInput
                twoLines={true}
                label="Name"
                name="name"
                icon={faTag}
              />
            </FormGroup>
            <FormGroup>
              <TextInput
                twoLines={true}
                label="Description"
                name="description"
                type="textarea"
                rows="5"
                icon={faMessage}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <LoadingButton loading={isLoading} type="submit" color="primary">
              Save
            </LoadingButton>
            <Button type="button" color="warning" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default ReasonModal;
