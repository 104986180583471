import React, { useState, useEffect } from "react";
import Table from "@src/components/DataTable/Table";
import { Card, CardBody } from "reactstrap";
import {
  useGetLzBaggageLogsQuery,
  useExportLzBaggageLogsMutation,
} from "@src/redux/lz-baggage/log";
import LzBaggageLogsColumns from "./columns";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { useParams } from "react-router-dom";
import Export from "../../common/Export";

const LzBaggageLogs = () => {
  const { itemId } = useParams();
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: null,
    keyword: "",
  });

  const initialDtFilters = {
    lzBaggageId: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    actionType: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    description: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  };

  const appendItemIdFilter = (itemId, currentFilters) => {
    const itemIdFilter = {
      logic: "and",
      filters: [
        {
          field: "lzBaggageId",
          operator: "eq",
          value: itemId,
        },
      ],
    };
    let updatedFilters = JSON.parse(JSON.stringify(currentFilters));
    if (updatedFilters.advancedFilter) {
      updatedFilters.advancedFilter.filters.push(itemIdFilter);
    } else {
      updatedFilters.advancedFilter = {
        logic: "or",
        filters: [itemIdFilter],
      };
    }

    return updatedFilters;
  };

  const [dtFilters, setDtFilters] = useState(initialDtFilters);
  const { data, isLoading } = useGetLzBaggageLogsQuery(
    itemId ? appendItemIdFilter(itemId, filters) : filters
  );
  const [exportLogs, { isLoading: isLoadingExportedLogs }] =
    useExportLzBaggageLogsMutation();
  const [isExportDisabled, setIsExportDisabled] = useState(false);
  useEffect(() => {
    setIsExportDisabled(!data?.items || data.items.length === 0);
  }, [data?.items]);

  const clearFilters = () => {
    setDtFilters(initialDtFilters);
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: null,
      keyword: "",
    });
  };

  useEffect(() => {
    if (itemId) {
      setFilters(appendItemIdFilter(itemId, filters));
    }
  }, [itemId]);

  const actionTemplate = () => (
    <Export
      filters={filters}
      exportFunction={exportLogs}
      exportedEntity={"lz-baggage-histories"}
      isLoading={isLoadingExportedLogs}
      disabled={isExportDisabled}
    />
  );

  return (
    <>
      <BreadCrumbs pageName="lzBaggageHistory" pageTitle="LZ Baggage History" />
      <Card>
        <CardBody>
          <Table
            metaData={{
              ...data?.metadata,
              loading: isLoading,
            }}
            columns={LzBaggageLogsColumns()}
            values={data?.items || []}
            dataKey="lzBaggageId"
            emptyMessage="No Logs Found..."
            filters={dtFilters}
            setTableState={setFilters}
            clearFilter={clearFilters}
            tableState={filters}
            actionTemplate={actionTemplate}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default LzBaggageLogs;
