import React from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRevertLzBaggageItemMutation } from "@src/redux/lz-baggage/list";
import { useNavigate } from "react-router-dom";
import ConfirmSweetAlert from "@src/components/shared/ConfirmSweetAlert";
import { SuccessToast } from "@src/components/shared/SuccessToast";

const RevertLzBaggage = ({ item, listButton = false }) => {
  const navigate = useNavigate();
  const [revertLzBaggage] = useRevertLzBaggageItemMutation();

  const handleRevertClick = async (item) => {
    ConfirmSweetAlert({
      title: `Are you sure , you need to Revert Lz-Baggage number (${item.moduleNumber}) to pending status?`,
      icon: "question",
      confirmButtonText: "Revert",
      showCancelButton: true,
      cancelButtonClasses: "btn btn-warning ms-1",
      cancelButtonText: "Cancel",
      onConfirm: async () => {
        await revertLzBaggage({ lzBaggageId: item.id })
          .unwrap()
          .then((fulfilled) => {
            SuccessToast(
              `Lz-Baggage with number (${item.moduleNumber})  has been reverted to pending status Successfully!`
            );
            navigate(`/baggage-management/lz-baggage/list`);
            toggle(false);
          })
          .catch((rejected) => {});
      },
    });
  };

  if (listButton)
    return (
      <>
        <Button
          style={{ padding: ".515rem .536rem" }}
          id="revert"
          className="btn-icon rounded-circle ms-1"
          outline
          color="dark"
          onClick={() => {
            handleRevertClick({ ...item });
          }}
        >
          <span className="text-uppercase">
            <FontAwesomeIcon
              icon={faClockRotateLeft}
              className="font-medium-3"
            />
          </span>
        </Button>
        <UncontrolledTooltip target="revert" placement="top">
          Revert
        </UncontrolledTooltip>
      </>
    );

  return (
    <>
      <Button
        outline
        type="button"
        color="secondary"
        onClick={() => handleRevertClick(item)}
      >
        <FontAwesomeIcon icon={faClockRotateLeft} className="font-medium-1" />
        &nbsp; Revert
      </Button>
    </>
  );
};

export default RevertLzBaggage;
